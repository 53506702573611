import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTimeout, useUpdateEffect } from 'usehooks-ts'
import { refContext } from '../../App'
import { NavIcon } from '../components'
import { useRefContext } from '../hooks/useRefContext'
import { searchContext } from './Main'

export const AddPlace = () => {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const selectRef = useRefContext()
  const [, setIsExpanded] = useContext(searchContext).isExpanded!
  const handleAddLocationConfirm = () => {
    navigate('/location/info', { state: pathname })
    setIsExpanded(false)
  }
  return (
    <div className="m-2 divide-y-2 overflow-y-auto">
      <div>
        <button onClick={() => navigate(-1)}>
          <NavIcon name="back" />
        </button>
        <span className="ml-2">Prochaine étape</span>
      </div>
      <div className="grid p-2 justify-items-center">
        <button
          className="slate-circle-button"
          ref={selectRef('addLocationConfirm')}
          onClick={handleAddLocationConfirm}
        >
          <NavIcon name="check" />
          <span>Confirmer</span>
        </button>
      </div>
    </div>
  )
}
