import { useLocation, useNavigate } from 'react-router-dom'
import { useRefContext } from '../hooks/useRefContext'
import { NavIcon } from './NavIcon'

export const DirectionCard = () => {
  const navigate = useNavigate()
  const {pathname, state} = useLocation()
  const selectRef = useRefContext()
  const handleBack = () => navigate(-1)
  const handleClose = () => navigate('/location/info')
  const handleNavigate = () => {
    navigate('/location/navigate', {state: pathname})
  }
  return (
    <div className="m-2 divide-y-2">
      <div className="flex justify-between">
        <div onClick={handleBack}>
          <NavIcon name="back" />
        </div>
        <div onClick={handleClose}>
          <NavIcon name="close" />
        </div>
      </div>
      <div className="h-32 m-2">
        <div className="font-bold text-3xl">Versailles</div>
        <div>78000</div>
      </div>
      <div className="grid grid-cols-6 grid-rows-4 gap-4 text-center">
        <div className="text-orange-700 col-span-1">
          <NavIcon name="battery" />
        </div>
        <div className="col-span-5">
          <span>Recharge nécessaire 0 % à l'arrivée</span>
        </div>
        <button className="col-span-6 blue-circle-button w-full">
          Arrêt pour recharge
        </button>
        <button
          className="col-span-5 slate-circle-button w-full"
          onClick={handleNavigate}
          ref={selectRef('mapNavigate')}
        >
          <NavIcon name="navigate" />
          <span>Démarrer</span>
        </button>
        <button className="slate-circle-button w-full">
          <NavIcon name="routes" />
        </button>
        <div className="col-span-6">
          <span>
            14 min<span> - 7.7 km</span>
          </span>
        </div>
      </div>
    </div>
  )
}
