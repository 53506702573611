import React, { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTimeout } from 'usehooks-ts'
import useLongPress from '../hooks/useLongPress'
import { useRefContext } from '../hooks/useRefContext'
import { searchContext } from '../pages'

export const Assistant: React.FC<{ onLongPress: () => void }> = ({
  onLongPress,
}) => {
  const navigate = useNavigate()
  const {pathname, state} = useLocation()
  const [callingAssistant, setCallingAssistant] =
    useContext(searchContext).callingAssistant!
  const selectRef = useRefContext()
  const assitantWidget = selectRef('assitantWidget')!
  useEffect(() => {
    if (callingAssistant) {
      setTimeout(() => {
        setCallingAssistant(false)
        navigate('/location/details', {state: pathname})
      }, 1500)
    }
  }, [callingAssistant])

  const onClick = () => setCallingAssistant(true)
  const longPressEvent = useLongPress({ onLongPress, onClick })
  return (
    <div className="bg-[url('https://www.gizmochina.com/wp-content/uploads/2019/08/logo-featured-google-assistant.jpg')] bg-cover bg-center h-full">
      <button
        className="h-full w-full"
        ref={assitantWidget}
        {...longPressEvent}
      ></button>
    </div>
  )
}
