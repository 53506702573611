import Tutorials from './app/pages/Tutorials'
import { createContext, useState } from 'react'
import RealApp from './app/pages/RealApp'
import { InitialRefObject } from './conf/properties'
import { useRefFocus } from './app/hooks/useRefFocus'
import { useTranslation } from 'react-i18next'
import { testYield } from './app/common'

export const switchContext = createContext<any>(null)
export const refContext = createContext(InitialRefObject)
const { Provider: RefProvider } = refContext
const { Provider } = switchContext
switchContext.displayName = 'Switch Context'
refContext.displayName = 'Ref Context'

const App: React.FC = () => {
  const [showModal, setShowModal] = useState<
    ['one' | 'two' | undefined, false]
  >([, false])
  const { i18n } = useTranslation()
  useRefFocus()
  const handleTestClick = () => {
    // console.log('profileInterface Ref', IntialRefObject.profileInterface.current)
    const generator = testYield(0)
    console.log(generator.next()) // 0
    console.log(generator.next()) // 1
    console.log(generator.next()) // 2
    console.log(generator.next()) // 3
    console.log(generator.next(10)) // 14
    console.log(generator.next()) // 15
    console.log(generator.next(10)) // 26
  }
  return (
    <Provider value={[showModal, setShowModal]}>
      <RefProvider value={InitialRefObject}>
        <div className="grid grid-cols-3 gap-4 overflow-hidden">
          <div className="col-span-2">
            <button onClick={handleTestClick}>Test</button>
            <RealApp />
          </div>
          <div className="col-span-1">
            <Tutorials />
          </div>
        </div>
      </RefProvider>
    </Provider>
  )
}

export default App
