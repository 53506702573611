import React from 'react'
import useLongPress from '../hooks/useLongPress'
import { BigText } from './BigText'

export const AutoParking: React.FC<{ onLongPress: () => void }> = ({
  onLongPress,
}) => {
  const longPressEvent = useLongPress({ onLongPress })
  return (
    <div className="bg-stone-600 h-full" {...longPressEvent}>
      <BigText>AutoParking</BigText>
    </div>
  )
}
