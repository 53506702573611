import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SamplePic from '../../assets/home-swiper-nav-on.jpg'
import classNames from 'classnames'
import { refContext } from '../../App'

export const PicOption = React.forwardRef<HTMLDivElement, {
  text: string
  pic?: HTMLImageElement
  className?: string
}>(({ text, className, pic }, ref) => {
  const navigate = useNavigate()
  
  const { energyPage, driveSettings, driveEcoMode } = useContext(refContext)
  const { t } = useTranslation()
  const selectRef = (menu: string) => {
    if (menu === 'energy') return energyPage
    if (menu === 'settings') return driveSettings
    if (menu === 'eco_mode') return driveEcoMode
    return undefined
  }
  const {pathname} = useLocation()
  const handleNavigate = () => {
    console.log(text)

    switch (text) {
      case 'energy':
        navigate('/drive/electric/energy', {state: pathname})
        break
      case 'settings':
        navigate('/drive/preferences', {state: pathname})
        break
      case 'eco_mode':
        // navigate('/drive/drive_eco', {state: pathname})
        break
      default:
        alert('Do you wish to quit the tutorial?')
        break
    }
  }
  return (
    <div
      className={classNames(
        'flex flex-col items-center bg-rounded-xl bg-stone-400 hover:ring-4',
        className
      )}
      onClick={handleNavigate}
      ref={ref}
    >
      <img src={SamplePic} alt="samplePic" className="w-24 h-24" />
      <div className="text-center">
        <span>{t(text)}</span>
      </div>
    </div>
  )
})
PicOption.displayName = "Pic Option"