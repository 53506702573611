import { SyntheticEvent, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { refContext } from '../../App'
import { searchContext } from '../pages'
import { Data } from './LeftTopCorner'
import { NavIcon } from './NavIcon'
import { SearchContent } from './SearchContent'
import { SideBar } from './SideBar'

const SideMenus = ['Récents', 'Catégories', 'Enreg.']

export const InfoCard = () => {
  const [searchText, setSearchText] = useState('')
  const [isExpanded, setIsExpanded] = useContext(searchContext).isExpanded!
  const { mapSearch, mapPlace } = useContext(refContext)
  const handleBlur = (e: SyntheticEvent) => {
    e.preventDefault()
    setIsExpanded(false)
  }
  const handleInputClicked = () => {
    setIsExpanded(true)
    const timer = setTimeout(() => mapPlace.current?.focus(), 1000)
    return () => {
      clearTimeout(timer)
    }
  }

  return (
    <div className={isExpanded ? 'w-[750px]' : 'w-96'}>
      <div className="flex border-px m-2 w-full">
        <label className="relative block w-11/12 mx-1">
          <span className="sr-only">Search</span>
          <span
            className="absolute inset-y-0 left-0 flex items-center pl-2"
            onClick={handleBlur}
          >
            <NavIcon name={isExpanded ? 'back' : 'location'} />
          </span>
          <input
            className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-3xl py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm grow"
            placeholder="Rechercher"
            type="search"
            name="search"
            onClick={handleInputClicked}
            // onBlur={handleBlur}
            onChange={(event) => setSearchText(event.target.value)}
            ref={mapSearch}
          />
          <span className="absolute inset-y-0 right-0 flex items-center pl-2">
            <NavIcon name="collapse" />
          </span>
        </label>
      </div>
      {isExpanded && (
        <div id="searchContent" className="flex w-full">
          <div id="searchSideBar" className="flex flex-col">
            <SideBar menus={SideMenus} />
          </div>
          <div className="ml-8 w-9/12">
            <SearchContent data={Data} />
          </div>
        </div>
      )}
      {!isExpanded && <BottomMenu />}
    </div>
  )
}
const Icons = ['gas_station', 'restaurant', 'shopping', 'drink']
const BottomMenu = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const handleClick = (icon: string) => navigate(`/location/nearby/${icon}`, {state: pathname})
  return (
    //  Bottom Bar
    <div className="flex justify-between px-4 py-2">
      {Icons.map((icon) => (
        <button
          className="hover:text-blue-600"
          key={icon}
          onClick={() => handleClick(icon)}
        >
          <NavIcon name={icon} />
        </button>
      ))}
    </div>
  )
}
