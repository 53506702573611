import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useState, useEffect } from 'react'
export const SideBar: React.FC<{ menus: any[] }> = ({ menus }) => {
  const [currentclicked, setcurrentclicked] = useState<typeof menus[number]>(
    menus[0]
  )
  useEffect(() => {
    const timer = setTimeout(() => setcurrentclicked(menus[0]), 300)

    return () => {
      clearTimeout(timer)
    }
  }, [currentclicked])

  return (
    <div className="text-slate-400 h-24 content-center items-center">
      <div className="justify-evenly font-bold">
        {menus.map((menu) => (
          <div
            key={menu}
            className={classNames(currentclicked === menu && 'text-blue-600')}
            onClick={() => {
              console.log('clicked')
              setcurrentclicked(menu)
            }}
          >
            <div className="relative pl-2">
              {menu}
              {currentclicked === menu && (
                <motion.div className="sideline" layoutId="underline" />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}