import React, { SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import { useRefContext } from '../hooks/useRefContext'
import { useNavigate } from 'react-router-dom'

interface Props {
  options?: OptionType[]
  onClick?: (...params: any[]) => any
  children?: React.ReactNode
  isExpandable?: boolean
  type?: string | undefined
  title: string
}
export const ExpandElement = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      title,
      children = title,
      options,
      onClick,
      isExpandable = false,
      type = undefined,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const handleExpand = () => {
      if (!isExpandable) return
      setIsOpen(!isOpen)
    }
    const selectRef = useRefContext()
    return (
      <div>
        <div className="flex hover:element-hover">
          <motion.div
            initial={false}
            animate={{ rotate: isOpen ? 90 : 0 }}
            transition={{ duration: 0.4 }}
          >
            {'>'}
          </motion.div>
          <button
            className="flex pl-4 focus:ring-4"
            ref={ref}
            onClick={isExpandable ? handleExpand : onClick}
          >
            {children}
          </button>
        </div>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate={isExpandable ? 'open' : 'collapsed'}
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <motion.div
                variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
                transition={{ duration: 0.8 }}
              >
                {options ? (
                  <SubPlainElement {...{ options, type, title }} />
                ) : (
                  <div className="flex flex-col pl-9">
                    <span>Option 1</span>
                    <span>Option 2</span>
                    <span>Option 3</span>
                    <span>Option 4</span>
                  </div>
                )}
              </motion.div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    )
  }
)
ExpandElement.displayName = 'ExpandElement'

const SubPlainElement: React.FC<{
  options: OptionType[]
  type: string | undefined
  title: string
}> = ({ options, type, title }) => {
  const { t, i18n } = useTranslation()
  const selectRef = useRefContext()
  const handleClick = (param: string) => {
    if(title === 'language_and_input') i18n.changeLanguage(param)
    const timer = setTimeout(
      () => selectRef('subPrefClose')?.current?.focus(),
      1000
    )
    return () => clearTimeout(timer)
  }
  const prefix = useMemo(() => {
    if (title === 'language_and_input') return 'lang'
    return ''
  }, [title])

  return (
    <div className="flex flex-col pl-9">
      {options.map((option, index) => (
        <div key={index}>
          {!type && (
            <button
              className="hover:element-hover"
              onClick={() => handleClick(option.title)}
            >
              {'  '}
              {t(`${prefix && `${prefix}.`}${option.title}`)}
            </button>
          )}
          {type === 'radio' && (
            <>
              <input
                type={type}
                value={option.title}
                name={options.toString()}
              />
              <label htmlFor={options.toString()}>
                {'  '}
                {t(`${prefix && `${prefix}.`}${option.title}`)}
              </label>
            </>
          )}
        </div>
      ))}
    </div>
  )
}
