import classNames from 'classnames'
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useRefContext } from '../hooks/useRefContext'

export const DriveConfigure = (props: any) => {
  const { carMode } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectRef = useRefContext()
  return (
    <div className="w-full h-full">
      <div className="flex justify-between">
        <div className="space-x-4">
          <button onClick={() => navigate('/drive')}>Back</button>
          <span>| {carMode}</span>
        </div>
        <button>Settings</button>
      </div>
      <div className="flex justify-around">
        <NavLink
          to="conduite"
          state={pathname}
          className={({ isActive }) =>
            classNames({ 'text-rose-700': isActive })
          }
        >
          <button className="focus:ring-4" ref={selectRef('driveConduct')}>
            Conduite
          </button>
        </NavLink>
        <NavLink
          to="life"
          state={pathname}
          className={({ isActive }) =>
            classNames({ 'text-rose-700': isActive })
          }
        >
          <button className="focus:ring-4" ref={selectRef('driveLife')}>
            Vie à bord
          </button>
        </NavLink>
      </div>
      <Outlet />
    </div>
  )
}
