import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useLongPress from '../hooks/useLongPress'
import { BigText } from './BigText'

export const DateAndTime: React.FC<{ onLongPress: () => void }> = ({
  onLongPress,
}) => {
  const longPressEvent = useLongPress({ onLongPress })
  const { t } = useTranslation()
  function formatCurrentTime(): string {
    const date = new Date()

    return t('intlDateTime', {
      val: date.toLocaleTimeString(),
    })
  }
  const [currentTime, setCurrentTime] = useState(formatCurrentTime)
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(formatCurrentTime())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="bg-stone-600 h-full" {...longPressEvent}>
      <BigText>
        Date And Time <br />
        {currentTime}
      </BigText>
    </div>
  )
}
