import React from 'react'
import classNames from 'classnames'
import { Outlet } from 'react-router-dom'
interface Props {
  // expandState: boolean
  // infoState: InfoState
}
export const LeftTopCorner: React.FC<Props> = ({  }) => {
  return (
    <div>
      <div
        className={classNames(
          'left-1 bg-slate-50 drop-shadow-lg rounded-lg z-5 absolute flex flex-col m-2 max-h-96',
          'w-fit' 
        )}
      >
        <Outlet />
      </div>
    </div>
  )
}

export const Data = [
  {
    title: 'Connexion',
    type: 'user',
    content:
      'Lorsque vous pourrez le faire en toute sécurite, connectez-vous pour consulter les lieux que vous avez recherches sur votre telephone',
  },
  {
    title: 'Station de recharge pour vehicles électriques',
    type: 'history',
    content: '4 Av. de Paris, 78000 Versailles',
    time: '14 min',
  },
  {
    title: 'Versailles',
    type: 'history',
    content: '78000',
    time: '14 min',
  },
]
