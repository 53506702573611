import React from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationShortCut } from '../../conf/properties'
import SamplePic from '../../assets/home-swiper-nav-on.jpg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRefContext } from '../hooks/useRefContext'
import { defaultCLick } from '../common'

export const NotificationCenter = React.forwardRef<
  HTMLDivElement,
  {
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
  }
>(({ setIsExpanded }, ref) => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const selectRef = useRefContext()
  const handleAvatorClick = () => {
    setIsExpanded(false)
    navigate('/profiles/users', {state: pathname})
  }
  return (
    <div
      className="flex flex-col w-full h-[600px]"
      ref={ref}
      id="notificationCenter2"
    >
      {/* WIdget Bar */}
      <div className="flex justify-between bg-stone-800 text-gray-400">
        <div className="flex items-end pt-2">
          <button ref={selectRef('profileInterface')} onClick={handleAvatorClick}>
            <img
              src={SamplePic}
              alt="User"
              className="rounded-full self-start"
            />
          </button>
          <div className="space-x-4 pl-4">
            {NotificationShortCut.map((menu) => (
              <NotificationButton title={menu} key={menu} />
            ))}
          </div>
        </div>
        <div className="self-end">
          <NotificationButton title="settings" />
        </div>
      </div>
      <div className="items-center mt-2 divide-y divide-slate-700">
        <NotificationBar
          appName="VMS Console"
          title="VMS Console"
          message="VMS Console Starts"
        />
        <NotificationBar
          appName="VMS Console"
          title="VMS Console"
          message="VMS Console Starts"
        />
      </div>
      <div className="text-center mt-2 mb-2">
        <span className="text-slate-100">Tout effacer</span>
      </div>
    </div>
  )
})
NotificationCenter.displayName = 'NotificationCenter'
const NotificationButton: React.FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation()
  return <button onClick={defaultCLick}>{t(title)}</button>
}
const NotificationBar: React.FC<{
  appName: string
  title: string
  message: string
}> = ({ appName, title, message }) => {
  const handleNotificationClick = () => {
    alert('Do you want to quit the tutorial?')
  }
  return (
    <div
      className="relative w-full h-24 flex flex-col bg-indigo-500 px-8 rounded-3xl"
      onClick={handleNotificationClick}
    >
      <div className="flex text-slate-500">{appName}</div>
      <div className="flex justify-between">
        <div className="text-slate-100 ">
          <div className="font-bold">{title}</div>
          <div>{message}</div>
        </div>
        <img src={SamplePic} alt="AppIcon" />
      </div>
    </div>
  )
}
