import { NavIcon } from "./NavIcon"

export const RouteBrief = () => { 
  return (
    <div className='divide-y-2 bg-zinc-900 text-white p-2 h-36 rounded-lg'>
      <div className='grid grid-cols-5 grid-rows-2 text-center'>
        <button className="col-start-3 col-end-4">
        <NavIcon name="straight" />
        </button>
        <div className="col-start-5">
          <NavIcon name="sound" />
        </div>
        <div className="col-span-6 text-start">Prendre la direction sud</div>
      </div>
      <div className="bg-zinc-700">
        <span>Puis</span>
        <NavIcon name="routes" />
      </div>
    </div>
  )
 }