import React, { ReactNode, SyntheticEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { TutorialsMenu } from '../../conf/properties'
import { refContext } from '../../App'
import { startSenario } from '../common'
import classNames from 'classnames'

const Tutorials = () => {
  const location = useLocation()
  return (
    <div className="tutorial-main">
      <h1>Tutorials</h1>
      <div className='break-words text-ellipsis'>{JSON.stringify(location)}</div>
      {TutorialsMenu.map((item, index) => (
        <ExpandElement item={item} key={index} index={index} />
      ))}
    </div>
  )
}

export default Tutorials

interface Props {
  options?: object
  item: typeof TutorialsMenu[number]
  index: number
  children?: React.ReactNode
}
const ExpandElement: React.FC<Props> = ({  item, index }) => {
  const [generator, setGenerator] = useState<Generator>()
  const [isOpen, setIsOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const { t } = useTranslation()
  const refObjects = useContext(refContext)
  const navigate = useNavigate()

  const keys = Object.keys(item.screens) as Array<keyof typeof item['screens']>
  const handleToggle = (event: SyntheticEvent) => {
    if(!isOpen) generator?.return(undefined)
    setIsOpen(!isOpen)
    setGenerator(startSenario[index](refObjects, navigate))
    setCurrentStep(0)
  }
  return (
    <details
      className="open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-6 rounded-lg"
      onToggle={handleToggle}
      open={isOpen}
    >
      <summary className="hover:tutorial-hover h-8 font-semibold">
        {t(item.title)}
      </summary>
      <ol className="list-decimal list-inside">
        {keys.map((option, index) => (
          <Step
            key={option}
            path={item.screens[option]}
            index={index}
            generator={generator}
            isOpen={isOpen}
            step={[currentStep, setCurrentStep]}
          >
            {option}
          </Step>
        ))}
      </ol>
    </details>
  )
}

const Step = React.memo<{
  index: number
  children: ReactNode
  path: string | undefined
  generator: Generator | undefined
  isOpen: boolean
  step: [number, React.Dispatch<React.SetStateAction<number>>]
}>(({ index, children, path, generator, isOpen, step }) => {
  const [isClicked, setIsClicked] = useState(false)
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = step
  const handleNext = () => {
    if (isClicked || index > currentStep) return
    setIsClicked(true)
    const {value} = generator?.next()!
    setCurrentStep(() => value as number)
    // console.log('handleNext');
  }
  
  useEffect(() => {setIsClicked(false)}, [isOpen]) 
  return (
    <div
      className={classNames("flex justify-between mb-px", {"line-through": isClicked})}
      onClick={handleNext}
    >
      <div className="pl-4">
        <li className={classNames({"text-slate-500": index > currentStep})}>
           {t(children as string)}
        </li>
      </div>
      <button
        className="tutorial-button"
        disabled={isClicked}
      >
        Next
      </button>
    </div>
  )
})
Step.displayName = "Step"

const aa = 'miao'