import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import type { Variants } from 'framer-motion'
import { BigText } from './BigText'
import { NotificationCenter } from '../pages'
import { useRefContext } from '../hooks/useRefContext'

export const StatusBar: React.FC = () => {
  const [offset, setOffset] = useState<number>(0)
  const [dragY, setDragY] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const { t } = useTranslation()
  const selectRef = useRefContext()
  function formatCurrentTime(): string {
    const date = new Date()

    return t('intlDateTime', {
      val: date,
      formatParams: {
        val: { hour: '2-digit', minute: '2-digit' },
      },
    })
  }
  const [currentTime, setCurrentTime] = useState(formatCurrentTime())
  const [currentTemp, setcurrentTemp] = useState('40ºF')

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) setOffset(ref.current.offsetHeight + 10)
    return () => {}
  }, [ref])
  useEffect(() => {
    const interval = setInterval(
      () => setCurrentTime(formatCurrentTime()),
      1000
    )
    const timer = setTimeout(() => {
      if (isExpanded) selectRef('profileInterface')?.current?.focus()
    }, 1000)

    return () => {
      clearInterval(interval)
      clearTimeout(timer)
    }
  }, [isExpanded])

  const variants: Variants = {
    expanded: {
      height: offset,
    },
    collapsed: {
      height: 0,
    },
  }
  return (
    <div
      className={`absolute left-0 top-0 w-[inherit] bg-stone-800/75 z-10 h-[${Math.ceil(
        dragY
      )}px]`}
      id="status_bar"
    >
      {/* status bar */}
      <div className="flex text-sky-50 justify-between">
        <div className="flex space-x-4">
          <div>1</div>
          <div>2</div>
        </div>
        <div className="flex space-x-4">
          <div>{currentTemp}</div>
          <div>{currentTime}</div>
        </div>
      </div>
      <motion.div
        variants={variants}
        initial="collapsed"
        animate={isExpanded ? 'expanded' : 'collapsed'}
        className="overflow-hidden z-10"
      >
        <NotificationCenter ref={ref} setIsExpanded={setIsExpanded} />
      </motion.div>
      {/* indicator */}
      <motion.div
        onClick={() => setIsExpanded(!isExpanded)}
        className="z-20 absolute w-full top-0 h-4"
      >
        <BigText>
          <button ref={selectRef('statusbarIndicator')}>
            <hr className="w-24" />
          </button>
        </BigText>
      </motion.div>
    </div>
  )
}
