import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTimeout } from 'usehooks-ts'
import SamplePic from '../../assets/home-swiper-nav-on.jpg'
import { useRefContext } from '../hooks/useRefContext'

const data = [
  {
    title: 'Poltava oblast',
  },
  {
    title: 'Guaviare',
  },
  {
    title: 'Morelos',
  },
  {
    title: 'Maine',
  },
  {
    title: 'Lambayeque',
  },
]
export const Launcher = () => {
  return (
    <div className="grid grid-cols-4 gap-4 place-content-around w-full overflow-auto pt-4">
      <PerApp item={{ title: 'Spotify' }} />
      <PerApp item={{ title: 'Google Play Store' }} />
      {data.map((item) => (
        <PerApp key={item.title} item={item} />
      ))}
      {data.map((item) => (
        <PerApp key={item.title} item={item} />
      ))}
      {data.map((item) => (
        <PerApp key={item.title} item={item} />
      ))}
      {data.map((item) => (
        <PerApp key={item.title} item={item} />
      ))}
      {data.map((item) => (
        <PerApp key={item.title} item={item} />
      ))}
    </div>
  )
}
const PerApp: React.FC<{ item: typeof data[number] }> = ({ item }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectRef = useRefContext()
  const handleLauncherClick = (appName: string) => {
    if (appName === 'Spotify' || appName === 'Google Play Store')
      navigate(appName, { state: pathname })
    else alert('Do you want to quit the tutorial?')
  }
  return (
    <button
      className="flex flex-col items-center"
      onClick={() => handleLauncherClick(item.title)}
      ref={selectRef(item.title)}
    >
      <img src={SamplePic} alt="samplePic" className="w-24 h-24" />
      <div className="text-center">
        <span>{t(item.title)}</span>
      </div>
    </button>
  )
}
