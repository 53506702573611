import classNames from 'classnames'
import React, { SyntheticEvent, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useUpdateEffect } from 'usehooks-ts'
import { hoverAndClick, sleep } from '../common'
import { useRefContext } from '../hooks/useRefContext'

export const DriveECO = React.forwardRef<HTMLDivElement, {expand?: boolean; className?: string }>(({
  className,
  expand = false,
}, ref) => {
  const [carMode, setCarMode] = useState<'ECO' | 'SPORT'>('ECO')
  const {pathname} = useLocation()
  const selectRef = useRefContext()
  const handleClick = (param: 'ECO' | 'SPORT', evt: SyntheticEvent) => {
    evt.stopPropagation()
    setCarMode(param)
  }
  const driveEcoSelect = selectRef('driveEcoSelect')!
  const driveEcoConfigure = selectRef('driveEcoConfigure')!
  const simulateClick = (function* () {
    yield hoverAndClick(driveEcoSelect)
    yield hoverAndClick(driveEcoConfigure)
  })()
  useUpdateEffect(() => {
    if (!expand) return
    (async () => {
      simulateClick.next()
      await sleep(1000)
      simulateClick.next()
    })()
  }, [expand])
  const defaultClick = (evt: SyntheticEvent) => {
    evt.stopPropagation()
    alert('Do you want to quit the tutorial?')
  }
  if (!expand) {
    return (
      <div
        className={classNames(
          'flex w-full h-full bg-rounded-xl',
          className && className
        )}
      >
        <button className="bg-transparent rounded-full m-2 text-slate-50 drop-shadow-lg ring-2 w-full h-3/5">
          ECO Mode
        </button>
      </div>
    )
  }
  return (
    <div
      className={classNames(
        'w-full h-full bg-rounded-xl p-4',
        className && className
      )}
      ref={ref}
    >
      <div>{carMode}</div>
      <div className="flex self-end place-content-between">
        <div className="space-x-4">
          <button onClick={defaultClick}>Cloud</button>
          <button
            onClick={(e) => handleClick('ECO', e)}
            className={classNames('rounded-full p-2  drop-shadow-lg ring-2', {
              'blue-circle-button': carMode === 'ECO',
            })}
          >
            ECO
          </button>
          <button
            onClick={(e) => handleClick('SPORT', e)}
            className={classNames('rounded-full p-2  drop-shadow-lg ring-2', {
              'blue-circle-button': carMode === 'SPORT',
            })}
            ref={selectRef('driveEcoSelect')}
          >
            Sport
          </button>
          <button onClick={defaultClick}>User</button>
        </div>
        <div className="self-end">
          <Link to={`configure/${carMode}/conduite`} state={pathname}>
            <button ref={selectRef('driveEcoConfigure')}>Configure</button>
          </Link>
        </div>
      </div>
    </div>
  )
})

DriveECO.displayName = 'Drive ECO'
