import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { refContext } from '../../App'
import { NavIcon } from './NavIcon'
import { useRefContext } from '../hooks/useRefContext'

const icons = ['options', 'light', 'credit_card', 'light_options']
const ExampleData: PlaceInfoProps[] = [
  {
    index: '1',
    title: 'Bornes New M...',
    timeRemain: 'Détour rapide',
    place: 'Lente',
    fraction: '2/6',
  },
  {
    index: '2',
    title: 'Station de rech...',
    timeRemain: 'Détour rapide',
    place: 'Moyenne',
    fraction: '3',
  },
  {
    index: '3',
    title: 'VIRTA Chargin...',
    remarks: 'Jouy-en-Josas',
    timeRemain: '(detour : + 5 min)',
    place: 'Moyenne',
    fraction: '2',
  },
  {
    index: '4',
    title: 'VIRTA Chargin...',
    remarks: 'Jouy-en-Josas',
    timeRemain: '(detour : + 5 min)',
    place: 'Moyenne',
    fraction: '2',
  },
  {
    index: '5',
    title: 'VIRTA Chargin...',
    remarks: 'Jouy-en-Josas',
    timeRemain: '(detour : + 5 min)',
    place: 'Moyenne',
    fraction: '2',
  },
  {
    index: '6',
    title: 'VIRTA Chargin...',
    remarks: 'Jouy-en-Josas',
    timeRemain: '(detour : + 5 min)',
    place: 'Moyenne',
    fraction: '2',
  },
  {
    index: '7',
    title: 'VIRTA Chargin...',
    remarks: 'Jouy-en-Josas',
    timeRemain: '(detour : + 5 min)',
    place: 'Moyenne',
    fraction: '2',
  },
  {
    index: '8',
    title: 'VIRTA Chargin...',
    remarks: 'Jouy-en-Josas',
    timeRemain: '(detour : + 5 min)',
    place: 'Moyenne',
    fraction: '2',
  },
]

export const NearByResult = () => {
  const navigate = useNavigate()
  const {pathname, state} = useLocation()
  const { category } = useParams()
  const { t } = useTranslation()
  const { placeClose, navSettingsClick } = useContext(refContext)
  const handleBack = () => navigate(-1)
  const handleClose = () => {
    navigate('/location/navigate', {state: pathname})
  }
  return (
    <div className="m-2 divide-y-2">
      <div className="flex justify-between">
        <div className="flex items-center">
          <button className="m-2" onClick={handleBack}>
            <NavIcon name="back" />
          </button>
          <span>Bornes de chargement</span>
          <span>{t(category || '')}</span>
        </div>
        <button onClick={handleClose} ref={placeClose}>
          <NavIcon name="close" />
        </button>
      </div>
      <div className="flex flex-col h-80 overflow-y-auto">
        <div className="flex items-center justify-between">
          {icons.map((icon) => (
            <div key={icon}>
              <NavIcon name={icon} />
            </div>
          ))}
        </div>
        {ExampleData.map((item, index) => (
          <PlaceInfo key={index} {...item} />
        ))}
      </div>
    </div>
  )
}
interface PlaceInfoProps {
  index: string
  title: string
  remarks?: string
  timeRemain: string
  place: string
  fraction: string
}
const PlaceInfo: React.FC<PlaceInfoProps> = ({
  index,
  title,
  remarks,
  timeRemain,
  fraction,
  place,
}) => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const selecRef = useRefContext()
  const navigateToPlaceDetails = () => navigate('/location/details', {state: pathname})
  return (
    <div className="flex items-center" onClick={navigateToPlaceDetails}>
      <div className="m-2 justify-center">
        <button className="slate-circle-button w-fit h-fit">{index}</button>
      </div>
      <button
        className="flex flex-col m-2 w-full"
        ref={selecRef('nearbyResult')}
        onClick={navigateToPlaceDetails}
      >
        <div className="font-bold">{title}</div>
        {remarks && <div>{remarks}</div>}
        <div>{timeRemain}</div>
        <div className="flex justify-between">
          <div>{place}</div>
          <div>{fraction}</div>
        </div>
      </button>
    </div>
  )
}
