import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRefContext } from '../hooks/useRefContext'
import { NavIcon } from './NavIcon'
import { Switch } from './Switch'

const ExampleData = [
  { title: 'Trafic', switchable: true },
  { title: 'Son du guidage' },
  { title: 'Réactivé', disabled: true },
  { title: "Options d'itinéraire" },
  { title: 'Plans hors connexion', disabled: true },
  { title: 'Centre de confidentialité' },
  { title: 'Filtres de paiement VE' },
  { title: 'À propos/Aide' },
]

export const NavSettings = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selecRef = useRefContext()
  const handleClose = () => navigate('/location/navigate', { state: pathname })
  return (
    <div className="m-2 divide-y-2">
      <div className="flex justify-between">
        <span>Paramètres</span>
        <button onClick={handleClose} ref={selecRef('navSettingsClose')!}>
          <NavIcon name="close" />
        </button>
      </div>
      {ExampleData.map((item, index) => (
        <RowButton key={index} {...item} />
      ))}
    </div>
  )
}
interface RowButtonProps {
  title: string
  switchable?: boolean
  disabled?: boolean
}
const RowButton: React.FC<RowButtonProps> = ({
  title,
  switchable = false,
  disabled = false,
}) => {
  return (
    <div className="flex items-center justify-between min-h-[32px]">
      <button {...{ disabled }} className="disabled:text-gray-500">
        {title}
      </button>
      {switchable && <Switch />}
    </div>
  )
}
