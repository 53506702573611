import React, { createRef } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContactsCategory } from '../../conf/properties'
import SamplePic from '../../assets/home-swiper-nav-on.jpg'
import { ExpandElement as div, ExpandElement, NavIcon } from '../components'
import { useRefContext } from '../hooks/useRefContext'

export const Profiles = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const selectRef = useRefContext()
  const { t } = useTranslation()
  const isElementExpandable = (title: string) => {
    if (title === 'Nom') return false
    if (title === 'Protection du profil') return false
    return true
  }
  const handleNavigate = (item: string) => {
    if (item === 'Nom') navigate('/profiles/modify/Driver', {state: pathname})
    if (item === 'Protection du profil') navigate(`/profiles/${item}`, {state: pathname})
  }
  const switchRef = (title: string) => {
    if (title === 'Protection du profil') return selectRef('profileProtection')
    if (title === 'Données & position partagées avec Renault') return selectRef('sharedData')
    return undefined
  }
  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="space-x-4">
          <button onClick={() => navigate('/location')}>
            <NavIcon name="back" />
          </button>
          <span>| Profile</span>
        </div>
        <button>Settings</button>
      </div>
      <div className="flex space-x-8">
        <User userName="Driver" permission="admin" />
        <User userName="invite" />
        <User />
      </div>
      <div>
        {ContactsCategory.map((category, index) => (
          <ExpandElement
            key={category.title}
            // onClick={() => handleCategoryClick(index)}
            isExpandable={isElementExpandable(category.title)}
            options={category.options}
            type={category.type}
            title={category.title}
            ref={switchRef(category.title)}
            onClick={() => handleNavigate(category.title)}
          >
            {t(category.title)}
          </ExpandElement>
        ))}
      </div>
    </div>
  )
}

const User: React.FC<{ userName?: string; permission?: string }> = ({
  userName,
  permission,
}) => {
  const navigate = useNavigate()
  const { name } = useParams()
  const { pathname } = useLocation()
  const selectRef = useRefContext()
  const displayName = () => {
    if (userName === 'Driver') return name || userName
    return userName
  }
  return (
    <div>
      <button
        onClick={() => navigate(`/profiles/modify/${userName}`, {state: pathname})}
        ref={userName === 'Driver' ? selectRef('userName') : undefined}
      >
        <img src={SamplePic} alt="User" className="rounded-full border z-10" />
        <div className="text-center">
          <div>{displayName()}</div>
          <div>{permission}</div>
        </div>
      </button>
    </div>
  )
}
