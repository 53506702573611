import React, { SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ContactsCategory, PreferencesMenu } from '../../conf/properties'
import { defaultCLick } from '../common'
import { ExpandElement, NavIcon } from '../components'
import { useRefContext } from '../hooks/useRefContext'

function selectItems(key: string) {
  if (key === 'system') return PreferencesMenu[9].options!
  if (key === 'Protection du profil') return ContactsCategory[2].options!
  if (key === 'Choisir verrouillage')
    return ContactsCategory[2].options![0].options!
  return []
}

export const SubPreferences = () => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const { option } = useParams()
  const { t } = useTranslation()
  const selectRef = useRefContext()
  const handleTestClick = () => selectRef('chooseLock')?.current?.focus()
  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="space-x-4">
          <button
            onClick={() => navigate(-1)}
            ref={selectRef('subPrefClose')}
            className="focus:ring-4"
          >
            <NavIcon name="back" />
          </button>
          <span>| {t(option || '')}</span>
          {/* <button onClick={handleTestClick}>Test</button> */}
        </div>
      </div>
      <div className="w-full">
        <MenuList title={option || ''} items={selectItems(option || '')} />
      </div>
    </div>
  )
}

interface Props {
  title: string
  items: OptionType[]
}
const MenuList: React.FC<Props> = ({ title, items }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectRef = useRefContext()
  const handleOnClick = (item: string) => {
    console.log('[MenuList] handleOnClick', item)
    if (item === 'Choisir verrouillage') {
      navigate(`${item}`, { state: pathname })
      return
    }
    return defaultCLick()
  }
  const isElementExpandable = (title: string) => {
    if (title === 'language_and_input') return true
    return false
  }
  const switchRef = (title: string) => {
    if (title === 'language_and_input') return selectRef('language_and_input')
    if (title === 'Choisir verrouillage') return selectRef('chooseLock')
    return undefined
  }
  return (
    <div id={title} className="p-4 divide-y divide-slate-700">
      {items.map(item => (
        <ExpandElement
          key={item.title}
          options={item.options}
          onClick={() => handleOnClick(item.title)}
          isExpandable={isElementExpandable(item.title)}
          title={item.title}
          ref={switchRef(item.title)}
        >
          {t(item.title)}
        </ExpandElement>
      ))}
    </div>
  )
}
