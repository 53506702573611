import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PreferencesMenu } from '../../conf/properties'

export const ChooseLanguage = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const handleClick = (lang: string) => {
    i18n.changeLanguage(lang)
    navigate('/location')
  }
  return (
    <div className="flex flex-col w-screen h-screen itmes-center justify-center">
      <div className="modal">
        <div className="pb-2">ChooseLanguage:</div>
        <div className="divide-y-2">
          {PreferencesMenu[9].options![0].options!.map((option) => (
            <div className="flex" key={option.title}>
              <button onClick={() => handleClick(option.title)}>
                {t(`lang.${option.title}`)}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
