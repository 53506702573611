import React from 'react'
import { useTranslation } from "react-i18next";

export const Info = () => {
  const {t} = useTranslation('translation')
  const handleInfoClick = () => {
    alert("Do you want to quit the tutorial?")
  }
  return (
    <div className="flex bg-stone-800 text-slate-400 mt-auto content-center items-center" onClick={handleInfoClick}>
      Temp 42ºF 
      </div>
  )
}
