import classNames from 'classnames'
import React, { FormEvent, useState } from 'react'
import { useRefContext } from '../hooks/useRefContext'
enum ChargeMode {
  Immediate,
  Preset,
  Program,
}
export const Charge = () => {
  const [chargeMode, setChargeMode] = useState(ChargeMode.Preset)
  const selectRef = useRefContext()
  const ChargeModeCard: React.FC<{ value: ChargeMode }> = ({ value }) => {
    const handleOnChange = (event: FormEvent<HTMLInputElement>) =>
      setChargeMode(parseInt(event.currentTarget.value))

    const tips = () => {
      let title
      switch (value) {
        case ChargeMode.Immediate:
          title = 'Charge immédi...'
          break
        case ChargeMode.Preset:
          title = 'Charge différée'
          break
        case ChargeMode.Program:
          title = 'Programme'
          break
      }
      return { title }
    }
    const isChecked = chargeMode === value
    const handleClick = () => { 
      if (isChecked) return 
      const timer = setTimeout(() => selectRef('energyPageBack')?.current?.focus(), 1000)
      return () => clearTimeout(timer) 
     }
    return (
      <div className={classNames("h-48 border-rounded-lg", {"bg-rose-700/25": isChecked})}>
        <input
          type="radio"
          name="chargeMode"
          id="immediate"
          value={value}
          onChange={handleOnChange}
          checked={isChecked}
          onClick={handleClick}
        />
        <label htmlFor="chargeMode">{tips().title}</label>
      </div>
    )
  }
  return (
    <div>
      Charge
      <div className="grid grid-cols-3 text-center">
        <ChargeModeCard value={ChargeMode.Immediate} />
        <ChargeModeCard value={ChargeMode.Preset} />
        <ChargeModeCard value={ChargeMode.Program} />
      </div>
    </div>
  )
}
