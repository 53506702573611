import { NavLink, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { MainMenu } from '../../conf/properties'
import classNames from 'classnames'
import { NavIcon } from './NavIcon'
import { useRefContext } from '../hooks/useRefContext'

export const NavBar = () => {
  const { t } = useTranslation()
  const {pathname} = useLocation()
  const selectRef = useRefContext()
  
  return (
    <div
      id="nav"
      className="bg-stone-800 text-slate-400 h-24 content-center items-center"
    >
      <div className="flex justify-evenly text-3xl font-bold pt-6">
        {MainMenu.map((menu) => (
          <NavLink
            to={menu}
            state={pathname}
            key={menu}
            className={({ isActive }) =>
              classNames(
                { 'text-rose-700': isActive },
                `hover:selected grow mx-2`
              )
            }
          >
            {({ isActive }) => (
              <div className="text-center">
                <button
                  className="pb-2"
                  ref={selectRef(menu)}
                >
                  <NavIcon name={menu} type="nav" />
                </button>
                {isActive ? (
                  <motion.div className="underline" layoutId="underline" />
                ) : null}
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  )
}
