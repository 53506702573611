import React from 'react'
import { ExpandElement } from '../components'

export const Conduite = () => {
  return (
    <div className="w-full divide-y divide-slate-700 ">
      <ExpandElement title="Moteur"/>
      <ExpandElement title="Effort de direction"/>
      <ExpandElement title="Dynamique véhicule"/>
    </div>
  )
}
