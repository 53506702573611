import {ExpandElement} from '../components'

export const DriveLife = () => {
  return (
    <div className="w-full">
      <ExpandElement title='Ambiance lumineur'/>
      <ExpandElement title='Ambiance lumineur'/>
      <ExpandElement title='Climatisation'/>
    </div>
  )
}

