import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import Spotify from '../../assets/spotify.png'
import GooglePlay from '../../assets/google_play.png'
import { NavIcon } from '../components'
import { useTimeout } from 'usehooks-ts'
import { useRefContext } from '../hooks/useRefContext'

export const AppPage = () => {
  const { appName } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const selectRef = useRefContext()
  const appPageBackIcon = selectRef('appPageBackIcon')!
  const PicUrl = appName === 'Spotify' ? Spotify : GooglePlay
  const handleBack = () => {
    navigate(-1)
  }
  return (
    <div className="relataive h-full w-full flex items-center justify-center">
      <button
        className="absolute left-0 top-[80px] h-16 w-16"
        onClick={handleBack}
        ref={appPageBackIcon}
      >
        <NavIcon name="back" />
      </button>
      <img src={PicUrl} alt={appName} />
    </div>
  )
}
