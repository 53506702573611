import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRefContext } from '../hooks/useRefContext'
import { NavIcon } from './NavIcon'

export const LeftBottomCorner: React.FC<{}> = ({}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const handleSettings = () => navigate('/location/settings', {state: pathname})
  return (
    <div className="absolute left-2 bottom-2">
      {pathname === '/location/navigate' && (
        <div className="m-2 p-2 divide-y-2 bg-slate-50 rounded-lg">
          <div className="flex">
            <button className="slate-circle-button m-2 items-center h-fit">
              <NavIcon name="close" />
            </button>
            <div className="p-2 flex flex-col">
              <span>
                <span className="text-teal-700">14 min</span> - 7.7 km
              </span>
              <span>
                14:52
                <span className="text-orange-700">
                  {`  0%`}
                  <NavIcon name="battery" />
                </span>
              </span>
            </div>
          </div>
          <BottomMenu />
        </div>
      )}
      {(pathname === '/location/info' || pathname === 'location/route') && (
        <button className="slate-circle-button" onClick={handleSettings}>
          <NavIcon name="settings" />
        </button>
      )}
    </div>
  )
}
const BottomMenu = () => {
  const navigate = useNavigate()
  const {pathname, state} =useLocation()
  const selectRef = useRefContext()
  console.log('[BottomMenu] navigate', pathname, state);
  const handleRouteSelect = () => {
    navigate('/location/route_select', {state: pathname} )
  }
  const handleSearch = () => navigate('/location/search', {state: pathname})
  const handleSettings = () => navigate('/location/settings', {state: pathname})
  
  return (
    //  Bottom Bar
    <div className="flex justify-between px-2 py-2">
      <button
        className="hover:text-blue-600"
        onClick={handleSettings}
        ref={selectRef('navSettingsClick')}
      >
        <NavIcon name="settings" />
      </button>
      <button
        className="hover:text-blue-600"
        onClick={handleRouteSelect}
        ref={selectRef('selectRoutes')}
      >
        <NavIcon name="routes" />
      </button>
      <button
        className="hover:text-blue-600"
        onClick={handleSearch}
        ref={selectRef('nearbySearch')}
      >
        <NavIcon name="search" />
      </button>
      <button className="hover:text-blue-600">
        <NavIcon name="location" />
      </button>
    </div>
  )
}
