import { PicOption } from '../components'
import {
  DriveMainMenuCollapsed,
  DriveMainMenuExpanded,
} from '../../conf/properties'
import { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { DriveECO } from '../components/DriveECO'
import { refContext } from '../../App'

export const DriveMain = () => {
  const [expand, setExpand] = useState(false)
  const [step, setStep] = useState(0)
  const {
    energyPage,
    driveSettings,
    driveEcoMode,
    driveEcoSelect,
    driveEcoConfigure,
  } = useContext(refContext)
  const menus = expand ? DriveMainMenuExpanded : DriveMainMenuCollapsed
  const simulateClick = (function* () {
    yield driveEcoSelect?.current?.focus()
    yield driveEcoSelect?.current?.click()
    yield driveEcoConfigure?.current?.focus()
  })()
  const selectRef = (menu: string) => {
    if (menu === 'energy') return energyPage
    if (menu === 'settings') return driveSettings
    if (menu === 'eco_mode') return driveEcoMode
    return undefined
  }

  return (
    <div className="w-full divide-y divide-slate-700">
      {/* <button className="slate-circle-button" onClick={() => simulateClick.next()}>
        Test Focus
      </button> */}
      <div
        className={classNames(
          'grid grid-cols-4',
          expand ? 'grid-rows-4 grid-flow-row' : 'grid-rows-3 grid-flow-col'
        )}
      >
        <div
          className={expand ? 'col-span-4' : 'col-span-2'}
          onClick={() => setExpand(!expand)}
        >
          <DriveECO expand={expand} className="bg-lime-700" ref={selectRef('eco_mode')} />
        </div>
        <div className={classNames({ 'col-span-2': !expand })}>
          <PicOption text="energy" className="bg-sky-700" ref={selectRef('energy')} />
        </div>
        {menus.map((menu) => (
          <PicOption key={menu} text={menu} ref={selectRef(menu)} />
        ))}
      </div>
    </div>
  )
}
