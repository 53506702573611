import React, { useState, useEffect, useRef, useContext } from 'react'
import { motion } from 'framer-motion'
import type { Variants } from 'framer-motion'
import { Outlet, useLocation } from 'react-router-dom'
import { Info, NavBar, StatusBar } from '../components'
import { SwitchWidgets } from './SwitchWidgets'
import { switchContext } from '../../App'
import { useRefContext } from '../hooks/useRefContext'

const PopupElement: React.FC<{ showModal: boolean }> = ({ showModal }) => {

  const ref = useRef<HTMLDivElement>(null)
  const [offset, setOffset] = useState<number>(0)
  useEffect(() => {
    if (ref.current) setOffset(ref.current.offsetHeight + 10)
    return () => {}
  }, [ref])

  const variants: Variants = {
    open: {
      y: -offset,
      visibility: 'visible',
    },
    closed: {
      y: 0,
      visibility: 'hidden',
    },
  }

  return (
    <div className="relative w-[750px] h-[850px]">
      <motion.div
        variants={variants}
        initial="closed"
        animate={showModal ? 'open' : 'closed'}
      >
        <SwitchWidgets ref={ref} />
      </motion.div>
    </div>
  )
}

const RealApp: React.FC = () => {
  const [showModal, setShowModal] = useContext(switchContext)
  const closeModal = () => {
    if (showModal[1]) {
      setShowModal([, false])
      const timer =  setTimeout(() => {
        if(state === '/launcher') selectRef('assitantWidget')!.current?.focus()
      }, 1000)
      return () => { clearTimeout(timer) }
    }
  }
  const {state} = useLocation()
  const selectRef = useRefContext() 
  return (
    <div>
      <div
        className="relative inset-20 w-[750px] h-[850px]"
        onClick={closeModal}
      >
        <StatusBar />
        <NavBar />
        <div id="content" className="flex h-4/6 self-stretch bg-main">
          <div className="bg-stone-500/50 w-full overflow-hidden">
            <Outlet />
          </div>
        </div>
        <Info />
        <PopupElement showModal={showModal[1]} />
      </div>
    </div>
  )
}

export default RealApp
