import {
  Circle,
  LocationOnOutlined,
  MusicNoteOutlined,
  PhoneBluetoothSpeakerOutlined,
  AppsOutlined,
  DirectionsCarOutlined,
  ArrowBackOutlined,
  LocalGasStationOutlined,
  RestaurantOutlined,
  ShoppingCartOutlined,
  LocalCafeOutlined,
  ExpandLessOutlined,
  MicOutlined,
  AccountCircleOutlined,
  SettingsOutlined,
  RestoreOutlined,
  CloseOutlined,
  BatteryAlertOutlined,
  NavigationOutlined,
  AltRouteOutlined,
  StraightOutlined,
  VolumeUpOutlined, 
  SearchOutlined,
  TuneOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined,
  TipsAndUpdatesOutlined,
  CreditCardOutlined,
  DynamicFormOutlined,
  AddLocationAltOutlined,
  MyLocationOutlined,
  Check, PlayCircle, PauseCircle,
  SkipNext, SkipPrevious
} from '@mui/icons-material'
import React, { useState, useMemo } from 'react'

export const NavIcon: React.FC<{ name: string, type?: string,}> = React.memo(
  ({ name, type }) => {
    const fontSize = useMemo(() => {
      switch (type) {
        case 'nav':
          return 'large' as const

        default:
          return 'medium' as const
      }
    }, [type])
    switch (name) {
      case 'location':
        return <LocationOnOutlined fontSize={fontSize} />
      case 'music':
        return <MusicNoteOutlined fontSize={fontSize} />
      case 'phone': 
        return <PhoneBluetoothSpeakerOutlined fontSize={fontSize} />
      case 'launcher':
        return <AppsOutlined fontSize={fontSize} />
      case 'drive':
        return <DirectionsCarOutlined fontSize={fontSize} /> 
      case 'back': 
        return <ArrowBackOutlined fontSize={fontSize} />
      case 'gas_station':
        return <LocalGasStationOutlined fontSize={fontSize} />
      case 'restaurant': 
        return <RestaurantOutlined fontSize={fontSize} />
      case 'shopping':
        return <ShoppingCartOutlined fontSize={fontSize} />
      case 'drink':
        return <LocalCafeOutlined fontSize={fontSize} /> //
      case 'collapse': 
        return <ExpandLessOutlined fontSize={fontSize} />
      case 'assistant_google':
        return <MicOutlined fontSize={fontSize} />
      case 'user': 
        return <AccountCircleOutlined fontSize={fontSize} />
      case 'settings': 
        return <SettingsOutlined fontSize={fontSize} />
      case 'history': 
        return <RestoreOutlined fontSize={fontSize} />
      case 'close': 
        return <CloseOutlined fontSize={fontSize} />
      case 'battery': 
        return <BatteryAlertOutlined fontSize={fontSize} />
      case 'navigate': 
        return <NavigationOutlined fontSize={fontSize} />
      case 'routes': 
        return <AltRouteOutlined fontSize={fontSize} />
      case 'straight': 
        return <StraightOutlined fontSize={fontSize} />
      case 'sound': 
        return <VolumeUpOutlined fontSize={fontSize} />
      case 'search': 
        return <SearchOutlined fontSize={fontSize} />
      case 'options': 
        return <TuneOutlined fontSize={fontSize} />
      case 'toggle_off': 
        return <ToggleOffOutlined fontSize={fontSize} />
      case 'toggle_on': 
        return <ToggleOnOutlined fontSize={fontSize} />
      case 'light': 
        return <TipsAndUpdatesOutlined fontSize={fontSize} />
      case 'credit_card': 
        return <CreditCardOutlined fontSize={fontSize} />
      case 'light_options': 
        return <DynamicFormOutlined fontSize={fontSize} />
      case 'add_location': 
        return <AddLocationAltOutlined fontSize={fontSize} />
      case 'current_location': 
        return <MyLocationOutlined fontSize={fontSize} />
      case 'check': 
        return <Check fontSize={fontSize} />
      case 'play': 
        return <PlayCircle fontSize={fontSize} />
      case 'pause': 
        return <PauseCircle fontSize={fontSize} />
      case 'skipNext': 
        return <SkipNext fontSize={fontSize} />
      case 'skipPrev': 
        return <SkipPrevious fontSize={fontSize} />
    }
    return <Circle />
  }
)
NavIcon.displayName = 'Icon'
