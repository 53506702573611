import React, { FormEvent, useState } from 'react'

enum Sound {
  Neutral,
  Pure,
  Expressive,
}

export const Settings = () => {
  const [soundMode, setSoundMode] = useState(Sound.Neutral)
  const selectSound = (event: FormEvent<HTMLInputElement>) => { 
    setSoundMode(parseInt(event.currentTarget.value))
   }
  return (
    <div className="m-2 divide-y-2 p-2 divide-gray-400">
      Settings
      <div className="flex flex-col">
        <div>Avertisseur sonore piéton</div>
      </div>
      <div className="flex flex-col" >
        <div>
          Son émis par le véhicule à fable vitesse pour sensibiliser les piétons
        </div>
        <div className="m-2">
          <input type="radio" id="neutral" value={Sound.Neutral} name='sound' onChange={selectSound} checked={soundMode === Sound.Neutral}/>
          <label htmlFor="neutral" className='pl-2'>Neutre</label>
        </div>
        <div className="m-2">
          <input type="radio" id="pure" value={Sound.Pure} name='sound' onChange={selectSound} checked={soundMode === Sound.Pure} />
          <label htmlFor="pure" className="pl-2">Pur</label>
        </div>
        <div className="m-2">
          <input type="radio" id="expressive" value={Sound.Expressive} name='sound' onChange={selectSound} checked={soundMode === Sound.Expressive}/>
          <label htmlFor="expressive" className='pl-2'>Expressif</label>
        </div>
      </div>
    </div>
  )
}
