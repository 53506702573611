import { useNavigate, NavLink, Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DriveEnergyMenu } from '../../conf/properties'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { NavIcon } from '../components'
import { useRefContext } from '../hooks/useRefContext'

export const Electric = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const selectRef = useRefContext()
  const {pathname} = useLocation()
  return (
    <div className="w-full h-full">
      <div className="flex justify-between">
        <div className="space-x-4">
          <button onClick={() => navigate('/drive', {state: pathname})} ref={selectRef('energyPageBack')}>
            <NavIcon name="back" />
          </button>
          <span>| Véhicule électrique</span>
        </div>
      </div>
      <div className="flex justify-between">
        {DriveEnergyMenu.map((menu) => (
          <NavLink
            to={menu}
            state={pathname}
            key={menu}
            className={({ isActive }) =>
              classNames(
                { 'text-rose-700': isActive },
                `hover:selected grow mx-2`
              )
            }
          >
            {({ isActive }) => (
              <div className="text-center">
                <button ref={selectRef(menu)}>
                  {t(menu)}
                </button>
                {isActive ? (
                  <motion.div className="underline" layoutId="underline" />
                ) : null}
              </div>
            )}
          </NavLink>
        ))}
      </div>
      <Outlet />
    </div>
  )
}
