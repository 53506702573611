import React from 'react'
export const BigText: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      {/* <div className="flex flex-col justify-center text-gray-200 font-xs font-bold"> */}
      <div>{children}</div>
    </div>
  )
}
