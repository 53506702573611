import React, { useContext, useEffect } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tracker } from '../components'
import { MusicMenu } from '../../conf/properties'
import classNames from 'classnames'
import { useRefContext } from '../hooks/useRefContext'

export const MusicRadio = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const selectRef = useRefContext()
  const audioFavorite = selectRef('audioFavorite')!
  const audioList = selectRef('audioList')!
  const handleMusicMenuClick = (key: typeof MusicMenu[number]) => {
    switch (key) {
      case 'scan':
        navigate('/music/scan')
        break
      default:
        alert('Do you wish to quit the tutorial?')
        break
    }
  }
  return (
    <div className="flex flex-col content-start w-full divide-y divide-slate-700 h-full">
      <div className="flex w-full justify-between">
        <div>Radio | FM</div>
        <div className="flex space-x-4">
          {MusicMenu.map((menu) => (
            <button
              key={menu}
              onClick={() => handleMusicMenuClick(menu)}
              className="hover:text-rose-700"
            >
              {t(menu)}
            </button>
          ))}
        </div>
      </div>
      <div className="flex justify-around">
        <NavLink
          to="/music/radio/list"
          state={pathname}
          className={({ isActive }) =>
            classNames({ 'text-rose-700': isActive })
          }
        >
          <button ref={audioList}>List</button>
        </NavLink>
        <NavLink
          to="/music/radio/favorites"
          state={pathname}
          className={({ isActive }) =>
            classNames({ 'text-rose-700': isActive })
          }
        >
          {' '}
          <button ref={audioFavorite}>Favorites</button>{' '}
        </NavLink>
      </div>
      <Outlet />
      <Tracker />
    </div>
  )
}
