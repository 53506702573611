import { useCallback, useContext } from 'react'
import { refContext } from '../../App'

export const useRefContext = () => {
  const refObjects = useContext(refContext)
  return (param: string) => {
    if (param === 'location') return refObjects.locationIcon
    if (param === 'mapPlace') return refObjects.mapPlace
    if (param === 'mapNavigate') return refObjects.mapNavigate
    if (param === 'selectRoutes') return refObjects.selectRoutes
    if (param === 'navSettingsClick') return refObjects.navSettingsClick
    if (param === 'nearbySearch') return refObjects.nearbySearch
    if (param === 'placeClose') return refObjects.placeClose
    if (param === 'navSettingsClose') return refObjects.navSettingsClose
    if (param === 'gasStationSearch') return refObjects.gasStationSearch
    if (param === 'nearbyResult') return refObjects.nearbyResult
    if (param === 'addLocation') return refObjects.addLocation
    if (param === 'addLocationConfirm') return refObjects.addLocationConfirm
    if (param === 'launcher') return refObjects.launcherIcon
    if (param === 'music') return refObjects.audioIcon
    if (param === 'phone') return refObjects.phoneIcon
    if (param === 'drive') return refObjects.driveIcon
    if (param === 'system') return refObjects.settingsSystem
    if (param === 'Spotify') return refObjects.spotifyIcon
    if (param === 'Google Play Store') return refObjects.googlePlayIcon
    if (param === 'appPageBackIcon') return refObjects.appPageBackIcon
    if (param === 'assitantWidget') return refObjects.assitantWidget
    if (param === 'placeDetailsClose') return refObjects.placeDetailsClose
    if (param === 'radio') return refObjects.radioIcon
    if (param === 'audioFavorite') return refObjects.audioFavorite
    if (param === 'audioList') return refObjects.audioList
    if (param === 'charge') return refObjects.chargeButton
    if (param === 'settings') return refObjects.settingsIcon
    if (param === 'energyPageBack') return refObjects.energyPageBack
    if (param === 'language_and_input') return refObjects.settingsLanguage
    if (param === 'subPrefClose') return refObjects.subPrefClose
    if (param === 'driveConduct') return refObjects.driveConduct
    if (param === 'driveLife') return refObjects.driveLife;
    if (param === 'driveEcoSelect') return refObjects.driveEcoSelect
    if (param === 'driveEcoConfigure') return refObjects.driveEcoConfigure
    if (param === 'statusbarIndicator') return refObjects.statusbarIndicator
    if (param === 'profileInterface') return refObjects.profileInterface
    if (param === 'userName') return refObjects.userName
    if (param === 'changeNameBack') return refObjects.changeNameBack
    if (param === 'profileProtection') return refObjects.profileProtection
    if (param === 'chooseLock') return refObjects.chooseLock
    if (param === 'sharedData') return refObjects.sharedData
    return null
  }
}
