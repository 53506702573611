import React from 'react'

const songs = [
  {
    "title": "massa"
  },
  {
    "title": "Vivamus rhoncus. Donec est. Nunc ullamcorper, velit in"
  },
  {
    "title": "felis, adipiscing fringilla, porttitor"
  },
  {
    "title": "tellus. Suspendisse sed dolor. Fusce"
  },
  {
    "title": "vel,"
  }
];
export const MusicList = () => {
  return (
    <div id="musicContent" className="p-4 divide-y divide-slate-700">
      {songs.map((song, index) => (
        <div className="h-8" key={index}>
          {song.title}
        </div>
      ))}
    </div>
  )
}
