import React, { SyntheticEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PreferencesMenu } from '../../conf/properties'
import { useRefContext } from '../hooks/useRefContext'
import { NavIcon } from '../components'

export const MainPreferences = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const { t } = useTranslation()
  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="space-x-4">
          <button onClick={() => navigate('/drive', {state: pathname}) }>
            <NavIcon name="back" />
          </button>
          <span>| Preferences</span>
        </div>
      </div>
      <div className="w-full">
        <button onClick={() => navigate('language')}>Languges</button>
        <MenuList title={t('settings')} items={PreferencesMenu} />
      </div>
    </div>
  )
}
interface Props {
  title: string
  items: (OptionType & {options?: OptionType[]})[]
}

const MenuList: React.FC<Props> = ({ title, items }) => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const selectRef = useRefContext()
  return (
    <div id={title} className="p-4 divide-y divide-slate-700">
      {items.map((item, index) => (
        <div className="h-8" key={index} onClick={() => navigate(item.title, {state: pathname})}>
          <button className="focus:ring-4" ref={selectRef(item.title)}>{item.title}</button>
        </div>
      ))}
    </div>
  )
}
