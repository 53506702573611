import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRefContext } from '../hooks/useRefContext'
import { Data } from './LeftTopCorner'
import { NavIcon } from './NavIcon'

export const SearchContent: React.FC<{ data: typeof Data }> = ({ data }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectRef = useRefContext()
  const handleClick = (title: string) => {
    if (title === 'Versailles') navigate('/location/route', { state: pathname })
  }
  return (
    <div className="w-full">
      {data.map((item, index) => (
        <button
          className={classNames(
            'flex text-slate-400 my-2 divide-y-2 divide-y-reverse hover:ring-sky-500 hover:ring-1',
            { 'text-blue-600': item.type === 'user' }
          )}
          key={index}
          onClick={() => handleClick(item.title)}
          ref={selectRef(index === 2 ? 'mapPlace' : '')}
        >
          <div className="self-center p-2">
            <NavIcon name={item.type} />
          </div>
          <div className="flex flex-col">
            <div className="font-bold leading-tight">{item.title}</div>
            <div className="leading-4 flex text-slate-400">
              <span>
                {item.time && (
                  <span className="text-teal-700">
                    {item.time}
                    {` - `}
                  </span>
                )}
                {item.content}
              </span>
            </div>
          </div>
        </button>
      ))}
    </div>
  )
}
