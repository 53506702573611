import React, { FormEvent, useContext, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { refContext } from '../../App'
import { BigText, NavIcon } from '../components'

export const ModifyName = () => {
  const { name } = useParams()
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const [newName, setNewName] = useState('')
  const { changeNameInput, changeNameBack } = useContext(refContext)
  const handleBack = () => navigate(-1)
  // const handleBack = () => {
  //   navigate(`/profiles/users/${newName}`, {state: pathname})
  // }
  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault()
    changeNameInput.current?.blur()
    console.log('Submit called', 'event value', evt.target)
    const timer = setTimeout(() => changeNameBack.current?.focus(), 1000)
    return () => clearTimeout(timer)
  }

  return (
    <div className="w-full h-full">
      ModifyName
      <div className="flex justify-between">
        <div className="space-x-4">
          <button onClick={handleBack} ref={changeNameBack}>
            <NavIcon name="back" />
          </button>
          <span>| Preferences</span>
        </div>
      </div>
      <form action="" method="get" onSubmit={handleSubmit}>
        <BigText>
          Nom:{' '}
          <input
            type="text"
            name="username"
            id="username"
            defaultValue={name}
            onChange={(event) => setNewName(event.target.value)}
            ref={changeNameInput}
          />
        </BigText>
      </form>
    </div>
  )
}
