import { useTranslation } from 'react-i18next'
import App from '../../App'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom'
import {
  Launcher,
  Main,
  Drive,
  Music,
  Phone,
  DriveMain,
  DriveConfigure,
  Conduite,
  DriveLife,
  Electric,
  Engergy,
  Charge,
  Battery,
  Settings,
  MainPreferences,
  Languages,
  SwitchWidgets,
  Profiles,
  MusicScan,
  MusicRadio,
  MusicList,
  MusicFavorites,
  SubPreferences,
  AddPlace,
  AppPage,
  DriveECO,
  ChooseLanguage,
} from '../pages'
import { useWidget } from '../hooks/useWidget'
import { createContext } from 'react'
import {
  DirectionCard,
  NearByResult,
  InfoCard,
  NavSettings,
  NearBySettings,
  PlaceDetails,
  PlaceResult,
  RouteBrief,
} from '../components'
import { ModifyName } from '../pages/ModifyName'

export const widgetContext = createContext<any[]>([])
const { Provider } = widgetContext
const BaseRouter = () => {
  const [state, dispatch] = useWidget()
  const {} = useTranslation()
  return (
    <Provider value={[state, dispatch]}>
      <Router>
        <Routes>
          <Route index element={<ChooseLanguage />} />
          <Route path="/" element={<App />}>
            <Route index element={<Navigate to="location" />} />
            <Route path="location" element={<Main />}>
              {/* <Route index element={<Navigate to="info" />} /> */}
              <Route index element={<InfoCard />} />
              <Route path="info" element={<InfoCard />} />
              <Route path="route" element={<DirectionCard />} />
              <Route path="navigate" element={<RouteBrief />} />
              <Route path="settings" element={<NavSettings />} />
              <Route path="search" element={<NearBySettings />} />
              <Route path="route_select" element={<PlaceResult />} />
              <Route path="nearby/:category" element={<NearByResult />} />
              <Route path="details" element={<PlaceDetails />} />
              <Route path="add_place" element={<AddPlace />} />
            </Route>
            <Route path="menu" element={<Drive />} />
            <Route path="music" element={<Outlet />}>
              <Route index element={<MusicScan />} />
              <Route path="scan" element={<MusicScan />} />
              <Route path="radio" element={<MusicRadio />}>
                <Route path="list" element={<MusicList />} />
                <Route path="favorites" element={<MusicFavorites />} />
              </Route>
              <Route path="scan" element={<MusicScan />} />
            </Route>
            <Route path="phone" element={<Phone />} />
            <Route path="launcher" element={<Launcher />} />
            <Route path="launcher/:appName" element={<AppPage />} />
            <Route path="drive" element={<Outlet />}>
              <Route index element={<DriveMain />} />
              <Route path="electric" element={<Electric />}>
                <Route path="energy" element={<Engergy />} />
                <Route path="charge" element={<Charge />} />
                <Route path="battery" element={<Battery />} />
                <Route path="settings" element={<Settings />} />
              </Route>
              <Route path="preferences" element={<Outlet />}>
                <Route index element={<MainPreferences />} />
                <Route path=":option" element={<SubPreferences />} />
              </Route>
              <Route path="configure/:carMode" element={<DriveConfigure />}>
                <Route path="conduite" element={<Conduite />} />
                <Route path="life" element={<DriveLife />} />
              </Route>
            </Route>
            <Route path="list_widgets" element={<SwitchWidgets />} />
            <Route path="profiles" element={<Outlet />}>
              <Route path="users" element={<Profiles />} />
              <Route path="users/:name" element={<Profiles />} />
              <Route path="modify/:name" element={<ModifyName />} />
              <Route path=":option" element={<SubPreferences />}>
                <Route path=":option" element={<SubPreferences />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>
    </Provider>
  )
}

export default BaseRouter
