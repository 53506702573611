import classNames from 'classnames'
import React, { useState } from 'react'
import { NavIcon } from './NavIcon'

export const Switch: React.FC<{on?: boolean}> = ({on = true}) => {
  const [toggle, setToggle] = useState(on)
  return (
    <button onClick={() => setToggle(!toggle)} className={classNames({'text-blue-600': toggle}, 'm-2')}>
      <NavIcon name={ toggle ? 'toggle_on' : 'toggle_off'} type='nav' /> 
    </button>
  )
}
