import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { MusicSource } from '../../conf/properties'
import SamplePic from '../../assets/home-swiper-nav-on.jpg'
import { useRefContext } from '../hooks/useRefContext'

export const MusicScan = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className="flex flex-col content-start w-full divide-y divide-slate-700 h-full">
      <div className="flex w-full justify-between px-2">
        <div>{t('sources')}</div>
        <button onClick={() => navigate('/music')}>x</button>
      </div>
      <div className="grid grid-cols-4 pt-2">
        {MusicSource.map((menu) => (
          <PerSource item={menu} key={menu} />
        ))}
      </div>
    </div>
  )
}
const PerSource: React.FC<{ item: typeof MusicSource[number] }> = ({
  item,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const selectRef = useRefContext()
  const handlePerSourceClick = (item: typeof MusicSource[number]) => {
    if (item === 'radio') navigate('/music/radio/list', { state: pathname })
  }
  return (
    <button
      // className="flex flex-col items-center"
      className={`flex flex-col items-center hover:text-zinc-400`}
      onClick={() => handlePerSourceClick(item)}
      ref={selectRef(item)}
    >
      <img src={SamplePic} alt="samplePic" className="w-24 h-24" />
      <div className="text-center">
        <span>{t(item)}</span>
      </div>
    </button>
  )
}
