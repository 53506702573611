import React from 'react'

export const MainMenu = ['location', 'music', 'phone', 'launcher', 'drive']
export const MusicMenu = ['search', 'equalizer', 'settings', 'scan']

export const MusicSource = [
  'android_auto',
  'apple_carplay',
  'audio_bluetooth',
  'google_news',
  'podcasts',
  'radio',
  'spotify',
  'usb',
  'youtube_music',
]

export const DriveMainMenuCollapsed = [
  'driving_aids',
  'parking_aids',
  'camera_360',
  'auto_parking',
  'air_quality',
  'driving_eco',
  'settings',
  'vehicle',
]
export const DriveMainMenuExpanded = [
  'driving_aids',
  'parking_aids',
  'camera_360',
  'seats',
  'auto_parking',
  'air_quality',
  'driving_eco',
  'settings',
  'vehicle',
]

export const DriveEnergyMenu = ['energy', 'charge', 'battery', 'settings']

export const WidgetMenu = [
  'audio',
  'driving_eco',
  'assistant_google',
  'ev_services',
  'auto_parking',
  'date_and_time',
  'air_quality',
  'tire_pressure',
]

export const NotificationShortCut = ['WLAN', 'Cellular', 'Bluetooth', 'Sounds']

export const ContactsCategory: OptionType[] = [
  { title: 'Nom' },
  { title: 'Image' },
  {
    title: 'Protection du profil',
    options: [
      {
        title: 'Choisir verrouillage',
        options: [
          { title: 'Aucun' },
          { title: 'Schéma' },
          { title: 'Code' },
          { title: 'Mot de passe' },
        ],
      },
      { title: 'Effacer les idestifiants' },
      { title: 'Déverrouiller profil avec le téléphone' },
      { title: 'Mise à jour de sécurité' },
    ],
  },
  { title: 'Compte My Renault' },
  { title: 'Compte Google' },
  {
    title: 'Données & position partagées avec Renault',
    type: 'radio',
    options: [
      { title: 'Partager les données et la position' },
      { title: 'Partager seulement les données' },
      { title: 'Refuser le partage' },
    ],
  },
  { title: 'Mentions légales' },
]
export const PreferencesMenu = [
  { title: 'sounds' },
  { title: 'units' },
  { title: 'wlan' },
  { title: 'bluetooth' },
  { title: 'location' },
  { title: 'applications_and_notifications' },
  { title: 'date_and_time' },
  { title: 'storage' },
  { title: 'profile_protection' },
  {
    title: 'system',
    options: [
      {
        title: 'language_and_input',
        options: [
          { title: 'en' },
          { title: 'fr' },
          { title: 'pt' },
          { title: 'es' },
          { title: 'nl' },
          { title: 'de' },
          { title: 'it' },
        ],
      },
      { title: 'about' },
      { title: 'legal_information' },
      { title: 'restore' },
      { title: 'android_auto' },
      { title: 'settings' },
    ],
  },
  { title: 'google' },
  { title: 'my_renault_and_vehicle' },
]

export const TutorialsMenu = [
  {
    title: 'tutorial-s1.title',
    screens: {
      'tutorial-s1.s1': '/location/info',
      'tutorial-s1.s2': '/location/info',
      'tutorial-s1.s3': '/location/route',
      'tutorial-s1.s4': '/location/navigate',
      'tutorial-s1.s5': '/location/route_select',
      'tutorial-s1.s6': '/location/navigateToPlaceDetails',
      'tutorial-s1.s7': '/location/settings',
      'tutorial-s1.s8': '/location/navigate',
      'tutorial-s1.s9': '/location/search',
      'tutorial-s1.s10': '/location/nearby',
      'tutorial-s1.s11': '/location/details',
      'tutorial-s1.s12': '/location/add_place',
    },
  },
  {
    title: 'tutorial-s2.title',
    screens: {
      'tutorial-s2.s1': 'paths1',
      'tutorial-s2.s2': 'paths2',
      'tutorial-s2.s3': 'paths3',
      'tutorial-s2.s4': 'paths4',
      'tutorial-s2.s5': 'paths5',
    },
  },
  {
    title: 'tutorial-s3.title',
    screens: {
      'tutorial-s3.s1': 'paths1',
      'tutorial-s3.s2': 'paths2',
    },
  },
  {
    title: 'tutorial-s4.title',
    screens: {
      'tutorial-s4.s1': 'paths1',
      'tutorial-s4.s2': 'paths2',
      'tutorial-s4.s3': 'paths3',
      'tutorial-s4.s4': 'paths4',
    },
  },
  {
    title: 'tutorial-s5.title',
    screens: {
      'tutorial-s5.s1': 'paths1',
      'tutorial-s5.s2': 'paths2',
    },
  },
  {
    title: 'tutorial-s6.title',
    screens: {
      'tutorial-s6.s1': 'paths1',
      'tutorial-s6.s2': 'paths2',
      'tutorial-s6.s3': 'paths3',
      'tutorial-s6.s4': 'paths4',
      'tutorial-s6.s5': 'paths5',
      'tutorial-s6.s6': 'paths6',
      'tutorial-s6.s7': 'paths7',
      'tutorial-s6.s8': 'paths8',
      'tutorial-s6.s9': 'paths9',
      'tutorial-s6.s10': 'paths10',
      'tutorial-s6.s11': 'paths11',
      'tutorial-s6.s12': 'paths12',
      'tutorial-s6.s13': 'paths13',
      'tutorial-s6.s14': 'paths14',
    },
  },
  {
    title: 'tutorial-s7.title',
    screens: {
      'tutorial-s7.s1': 'paths1',
      'tutorial-s7.s2': 'paths2',
      'tutorial-s7.s3': 'paths3',
      'tutorial-s7.s4': 'paths4',
      'tutorial-s7.s5': 'paths5',
      'tutorial-s7.s6': 'paths6',
      'tutorial-s7.s7': 'paths7',
      'tutorial-s7.s8': 'paths8',
    },
  },
]

export const InitialRefObject = {
  locationIcon: React.createRef<HTMLButtonElement>(),
  mapSearch: React.createRef<HTMLInputElement>(),
  mapPlace: React.createRef<HTMLButtonElement>(),
  mapNavigate: React.createRef<HTMLButtonElement>(),
  selectRoutes: React.createRef<HTMLButtonElement>(),
  placeClose: React.createRef<HTMLButtonElement>(),
  navSettingsClick: React.createRef<HTMLButtonElement>(),
  navSettingsClose: React.createRef<HTMLButtonElement>(),
  nearbySearch: React.createRef<HTMLButtonElement>(),
  gasStationSearch: React.createRef<HTMLButtonElement>(),
  nearbyResult: React.createRef<HTMLButtonElement>(),
  addLocation: React.createRef<HTMLButtonElement>(),
  addLocationConfirm: React.createRef<HTMLButtonElement>(),
  launcherIcon: React.createRef<HTMLButtonElement>(),
  spotifyIcon: React.createRef<HTMLButtonElement>(),
  googlePlayIcon: React.createRef<HTMLButtonElement>(),
  appPageBackIcon: React.createRef<HTMLButtonElement>(),
  assitantWidget: React.createRef<HTMLButtonElement>(),
  placeDetailsClose: React.createRef<HTMLButtonElement>(),
  audioIcon: React.createRef<HTMLButtonElement>(),
  radioIcon: React.createRef<HTMLButtonElement>(),
  audioFavorite: React.createRef<HTMLButtonElement>(),
  audioList: React.createRef<HTMLButtonElement>(),
  phoneIcon: React.createRef<HTMLButtonElement>(),
  driveIcon: React.createRef<HTMLButtonElement>(),
  settingsIcon: React.createRef<HTMLButtonElement>(),
  energyPage: React.createRef<HTMLDivElement>(),
  chargeButton: React.createRef<HTMLButtonElement>(),
  energyPageBack: React.createRef<HTMLButtonElement>(),
  driveSettings: React.createRef<HTMLDivElement>(),
  settingsSystem: React.createRef<HTMLButtonElement>(),
  settingsLanguage: React.createRef<HTMLButtonElement>(),
  subPrefClose: React.createRef<HTMLButtonElement>(),
  driveEcoMode: React.createRef<HTMLDivElement>(),
  driveEcoSelect: React.createRef<HTMLButtonElement>(),
  driveEcoConfigure: React.createRef<HTMLButtonElement>(),
  driveConduct: React.createRef<HTMLButtonElement>(),
  driveLife: React.createRef<HTMLButtonElement>(),
  statusbarIndicator: React.createRef<HTMLButtonElement>(),
  profileInterface: React.createRef<HTMLButtonElement>(),
  userName: React.createRef<HTMLButtonElement>(),
  changeNameInput: React.createRef<HTMLInputElement>(),
  changeNameBack: React.createRef<HTMLButtonElement>(),
  profileProtection: React.createRef<HTMLButtonElement>(),
  chooseLock: React.createRef<HTMLButtonElement>(),
  sharedData: React.createRef<HTMLButtonElement>(),
}
