import { RefObject } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { InitialRefObject } from '../../conf/properties'

export const defaultCLick = () => {
  alert('Do you want to quit the tutorial?')
}
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
function simulateClick(element: HTMLElement | null) {
  if (element) element.click()
  return
}
function simulateInput(element: HTMLInputElement | null, input: string) {
  console.log('simulateInput', input)

  if (element) element.value = input
  element?.blur()
}
export function hoverAndClick<
  T extends typeof InitialRefObject[K],
  K extends keyof typeof InitialRefObject = keyof typeof InitialRefObject
>(
  ref: Extract<
    T,
    RefObject<HTMLButtonElement | HTMLInputElement | HTMLDivElement>
  >
) {
  // console.log('hoverAndClick')
  if (document.activeElement !== ref.current) focusOrHightlight(ref.current)
  else simulateClick(ref.current)
  const timer = setTimeout(simulateClick, 1000, ref.current)
  return () => clearTimeout(timer)
}

function focusOrHightlight<
  T extends HTMLDivElement | HTMLButtonElement | HTMLInputElement
>(element: T | null) {
  if (element instanceof HTMLDivElement)
    element.className = `${element.className} ring-4`
  else element?.focus()
}

function hoverAndChangeInput<
  T extends typeof InitialRefObject[K],
  K extends keyof typeof InitialRefObject = keyof typeof InitialRefObject
>(ref: Extract<T, RefObject<HTMLInputElement>>, input?: string | number) {
  console.log('hoverAndChangeInput')
  if (!ref.current) return
  if (document.activeElement !== ref.current) ref.current?.focus()
  else if (input) simulateInput(ref.current, input.toString())
  if (!input) return
  const timer = setTimeout(
    () => simulateInput(ref.current, input.toString()),
    1000
  )
  return () => clearTimeout(timer)
}
function* startSenario1(
  refObjects: typeof InitialRefObject,
  navigate: NavigateFunction
) {
  let index = 0
  ;(async function () {
    navigate('/location')
    await sleep(1000)
    hoverAndClick(refObjects.mapSearch)
  })()
  yield ++index
  hoverAndClick(refObjects.mapPlace)
  yield ++index
  hoverAndClick(refObjects.mapNavigate)
  yield ++index
  hoverAndClick(refObjects.selectRoutes)
  yield ++index
  hoverAndClick(refObjects.placeClose)
  yield ++index
  hoverAndClick(refObjects.navSettingsClick)
  yield ++index
  hoverAndClick(refObjects.navSettingsClose)
  yield ++index
  hoverAndClick(refObjects.nearbySearch)
  yield ++index
  hoverAndClick(refObjects.gasStationSearch)
  yield ++index
  hoverAndClick(refObjects.nearbyResult)
  yield ++index
  hoverAndClick(refObjects.addLocation)
  yield ++index
  hoverAndClick(refObjects.addLocationConfirm)
  yield ++index
}
export function* startSenario2(
  refObjects: typeof InitialRefObject,
  _: NavigateFunction
) {
  let index = 0
  hoverAndClick(refObjects.launcherIcon)
  yield ++index
  hoverAndClick(refObjects.spotifyIcon)
  yield ++index
  hoverAndClick(refObjects.appPageBackIcon)
  yield ++index
  hoverAndClick(refObjects.googlePlayIcon)
  yield ++index
  hoverAndClick(refObjects.appPageBackIcon)
  yield ++index
}
function* startSenario3(
  refObjects: typeof InitialRefObject,
  navigate: NavigateFunction
) {
  let index = 0
  ;(async function () {
    navigate('/location')
    await sleep(1000)
    hoverAndClick(refObjects.assitantWidget)
    // console.log('[assistant widget]', refObjects.assitantWidget.current);
  })()
  yield ++index
}
function* startSenario4(
  refObjects: typeof InitialRefObject,
  _: NavigateFunction
) {
  let index = 0
  hoverAndClick(refObjects.audioIcon)
  yield ++index
  hoverAndClick(refObjects.radioIcon)
  yield ++index
  hoverAndClick(refObjects.audioFavorite)
  yield ++index
  hoverAndClick(refObjects.audioList)
  yield ++index
}
function* startSenario5(
  refObjects: typeof InitialRefObject,
  _: NavigateFunction
) {
  let index = 0
  hoverAndClick(refObjects.phoneIcon)
  yield ++index
}
function* startSenario6(
  refObjects: typeof InitialRefObject,
  _: NavigateFunction
) {
  let index = 0
  ;(async function () {
    hoverAndClick(refObjects.driveIcon)
    await sleep(3000)
    hoverAndClick(refObjects.energyPage)
  })()
  yield ++index
  hoverAndClick(refObjects.chargeButton)
  yield ++index
  hoverAndClick(refObjects.settingsIcon)
  yield ++index
  hoverAndClick(refObjects.energyPageBack)
  yield ++index
  hoverAndClick(refObjects.driveSettings)
  yield ++index
  hoverAndClick(refObjects.settingsSystem)
  yield ++index
  hoverAndClick(refObjects.settingsLanguage)
  yield ++index
  ;(async function () {
    hoverAndClick(refObjects.subPrefClose)
    await sleep(1000)
    hoverAndClick(refObjects.subPrefClose)
  })()
  yield ++index
  hoverAndClick(refObjects.driveEcoMode)
  yield ++index
  hoverAndClick(refObjects.driveLife)
  yield ++index
  hoverAndClick(refObjects.driveConduct)
  yield ++index
}

function* startSenario7(
  refObjects: typeof InitialRefObject,
  _: NavigateFunction
) {
  let index = 0
  hoverAndClick(refObjects.statusbarIndicator)
  yield ++index
  hoverAndClick(refObjects.profileInterface)
  yield ++index
  hoverAndClick(refObjects.userName)
  yield ++index
  ;(async function () {
    hoverAndChangeInput(refObjects.changeNameInput, 'Mike')
    await sleep(2000)
    hoverAndClick(refObjects.changeNameBack)
  })()
  yield ++index
  hoverAndClick(refObjects.profileProtection)
  yield ++index
  hoverAndClick(refObjects.chooseLock)
  yield ++index
  ;(function () {
    hoverAndClick(refObjects.subPrefClose)
    hoverAndClick(refObjects.subPrefClose)
  })()
  yield ++index
  hoverAndClick(refObjects.sharedData)
  yield ++index
  refObjects.changeNameBack.current?.click()
  yield ++index
  refObjects.profileProtection.current?.click()
  yield ++index
  refObjects.chooseLock.current?.click()
  yield ++index
  refObjects.subPrefClose.current?.click()
  yield ++index
  refObjects.subPrefClose.current?.click()
  yield ++index
}

export const startSenario = [
  startSenario1,
  startSenario2,
  startSenario3,
  startSenario4,
  startSenario5,
  startSenario6,
  startSenario7,
] as const


export function* testYield(value: number) {
  let step: number;
 
  while (true) {
    step = yield value++;
 
    if (step) {
      value += step;
    }
  }
 }
