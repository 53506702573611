import React from 'react'
import { NavIcon } from './NavIcon'

export const RightUpCorner = () => {
  return (
    <div className="absolute right-0">
      <div className="grid grid-cols-2 gap-2">
        <div className="slate-circle-button">
          <NavIcon name="assistant_google" />
        </div>
        <div className="slate-circle-button">
          <NavIcon name="user" />
        </div>
      </div>
    </div>
  )
}
