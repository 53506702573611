import React, { useState } from 'react'
import SamplePic from '../../assets/home-swiper-nav-on.jpg'

export const Tracker = () => {
  const [playingState, setPlayingState] = useState<boolean>(false)
  const skipPrev = () => {
    alert('No Prev Track!')
  }
  const playOrPause = () => {
    setPlayingState(!playingState)
  }
  const skipNext = () => {
    alert('No Next Track!')
  }
  return (
    <div className="flex justify-between h-1/6 mt-auto bg-slate-200">
      <div id="musicInfo" className="flex space-x-4">
        <img src={SamplePic} alt="Album" className="h-24 w-24" />
        <div>
          <div className="pt-2">Title</div>
          <div className="pt-2">Artist</div>
        </div>
      </div>
      <div id="controlBox" className="flex space-x-4 items-center">
        <button onClick={() => skipPrev()}>Prev</button>
        <button onClick={() => playOrPause()}>
          {playingState ? 'Pause' : 'Play'}
        </button>
        <button onClick={() => skipNext()}>Next</button>
      </div>
    </div>
  )
}
