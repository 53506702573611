import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { refContext } from '../../App'
import { useRefContext } from '../hooks/useRefContext'
import { NavIcon } from './NavIcon'

const ExampleData: RowButtonProps[] = [
  { icon: 'assistant_google', title: 'Recherche vocale' },
  { icon: 'gas_station', title: 'Bornes de chargement' },
  { icon: 'restaurant', title: 'Restaurants' },
  { icon: 'shopping', title: 'Supérettes' },
  { icon: 'drink', title: 'Cafés' },
]

export const NearBySettings = () => {
  const navigate = useNavigate()
  const {} = useContext(refContext)
  const handleClose = () => navigate('/location/navigate')
  return (
    <div className="m-2 divide-y-2">
      <div className="flex justify-between">
        <span>Rechercher sur le tra...</span>
        <button onClick={handleClose}>
          <NavIcon name="close" />
        </button>
      </div>
      {ExampleData.map((item, index) => (
        <RowButton key={index} {...item} />
      ))}
    </div>
  )
}

interface RowButtonProps {
  icon: string
  title: string
}
const RowButton: React.FC<RowButtonProps> = ({ icon, title }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectRef = useRefContext()
  const switchRef = (icon: string) => { 
    if (icon === 'gas_station') return selectRef('gasStationSearch')
    return undefined
   }
  const handleNearBySelect = (type: string) =>
    navigate(`/location/nearby/${type}`, { state: pathname })

  return (
    <div className="flex min-h-[32px]">
      <button
        className="flex items-center"
        ref={() => switchRef(icon)}
        onClick={() => handleNearBySelect(icon)}
      >
        <div className="m-2">
          <NavIcon name={icon} />
        </div>
        <span>{title}</span>
      </button>
    </div>
  )
}
