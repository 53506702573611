import React, { useState } from 'react'
import useLongPress from '../hooks/useLongPress'
import { NavIcon } from './NavIcon'

export const FmBox: React.FC<{ onLongPress: () => void }> = ({
  onLongPress,
}) => {
  const [playingState, setPlayingState] = useState<boolean>(false)
  const handleFmPrev = () => {
    alert('No Prev Track!')
  }
  const handleFmPlay = () => {
    setPlayingState(!playingState)
  }
  const handleFmNext = () => {
    alert('No Next Track!')
  }
  const longPressEvent = useLongPress({ onLongPress })
  return (
    <div className="bg-stone-600 h-full" {...longPressEvent}>
      <span>FmBox</span>
      <div className="flex justify-between mx-12 my-6">
        <button onClick={handleFmPrev}>
          <NavIcon name="skipPrev" type='nav' />
        </button>
        <button onClick={handleFmPlay}>
          <NavIcon name={playingState ? 'pause' : 'play'} type='nav' />
        </button>
        <button onClick={handleFmNext}>
          <NavIcon name="skipNext" type='nav' />
        </button>
      </div>
    </div>
  )
}
