import React, { createContext, useContext, useMemo, useState } from 'react'
import { switchContext } from '../../App'
import {
  LeftTopCorner,
  FmBox,
  Assistant,
  EvServices,
  AirQuality,
  AutoParking,
  DateAndTime,
  TirePressure,
  DrivingEco,
  LeftBottomCorner,
  RightUpCorner,
} from '../components'
import { WidgetMenu } from '../../conf/properties'
import { widgetContext } from '../router'
import { useNavigate } from 'react-router-dom'

type UseStateBoolean = [boolean, React.Dispatch<React.SetStateAction<boolean>>]
export const searchContext = createContext<{
  callingAssistant?: UseStateBoolean,
  isExpanded?: UseStateBoolean
}>({})
const { Provider } = searchContext
export enum InfoState {
  Info,
  Route,
  Navigate,
  Search,
  RouteSelect,
  Settings,
  GasStationSelect,
}
export const Main = () => {
  const [callingAssistant, setCallingAssistant] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const handleMapsClick = () => {
    setIsExpanded(false)
  }
  return (
    <Provider
      value={{
        callingAssistant: [callingAssistant, setCallingAssistant],
        isExpanded: [isExpanded, setIsExpanded],
      }}
    >
      <div className="flex flex-col justify-items-stretch">
        <div id="map" className="relative">
          <LeftTopCorner />
          <RightUpCorner />
          <img
            src="https://miro.medium.com/max/1400/1*8ZGC79f70ZpDbwrhYha-xA.png"
            alt="map"
            width="100%"
            height="100%"
            onClick={handleMapsClick}
          />
          <LeftBottomCorner />
        </div>
        {callingAssistant && (
          <div className="absolute left-0 h-16 w-full top-[600px] bg-purple-500 z-5 rounded-full ">
            <div className="w-full h-full bg-[url('https://assets.bose.com/content/dam/Bose_DAM/Web/consumer_electronics/global/general/icons/amazon_alexa/google_white_horz_lg_custom.png/jcr:content/renditions/original')] bg-contain bg-center bg-no-repeat "></div>
          </div>
        )}
        <WidgetBox />
      </div>
    </Provider>
  )
}
const WidgetBox: React.FC = () => {
  const [, set] = useContext(switchContext)
  const [state] = useContext(widgetContext)
  const onLongPressOne = () => {
    setTimeout(() => {
      set(['one', true])
    }, 800)
  }
  const onLongPressTwo = () => {
    setTimeout(() => {
      set(['two', true])
    }, 800)
  }
  const widgetOne = useMemo(() => state.one, [state.one])
  const widgetTwo = useMemo(() => state.two, [state.two])

  const SelectedWidget: React.FC<{
    widget: typeof WidgetMenu[number]
    onLongPress: () => void
  }> = ({ widget, onLongPress }) => {
    switch (widget) {
      case 'audio':
        return <FmBox onLongPress={onLongPress} />
      case 'driving_eco':
        return <DrivingEco onLongPress={onLongPress} />
      case 'assistant_google':
        return <Assistant onLongPress={onLongPress} />
      case 'ev_services':
        return <EvServices onLongPress={onLongPress} />
      case 'auto_parking':
        return <AutoParking onLongPress={onLongPress} />
      case 'date_and_time':
        return <DateAndTime onLongPress={onLongPress} />
      case 'air_quality':
        return <AirQuality onLongPress={onLongPress} />
      case 'tire_pressure':
        return <TirePressure onLongPress={onLongPress} />
      default:
        return <></>
    }
  }
  return (
    <div
      id="toolbox"
      className="mt-auto grid grid-cols-2 h-32 bg-transparent divide-x-2"
    >
      <div className="m-1 col-span-1 border-2 border-stone-50 border-solid rounded-md active:border-sky-300">
        <SelectedWidget widget={widgetOne} onLongPress={onLongPressOne} />
      </div>
      <div className="m-1 col-span-1 border-2 border-stone-50 border-solid rounded-md active:border-sky-300">
        <SelectedWidget widget={widgetTwo} onLongPress={onLongPressTwo} />
      </div>
    </div>
  )
}
