import { useLocation, useNavigate } from 'react-router-dom'
import { useRefContext } from '../hooks/useRefContext'
import { NavIcon } from './NavIcon'

interface Facility {
  title: string
  subtitle: string
  power: string
  amount: number
}
interface PlaceDetailsProps {
  place: string
  address: string
  compatibles: Facility[]
  imcompatibles: Facility[]
  timeCost: string
  distance: string
}
const ExampleData: PlaceDetailsProps = {
  place: 'Station de recharge pour vehicles éle...',
  address: '4 Av. de Paris 78000 Versailles',
  compatibles: [
    { title: 'Moyenne', subtitle: 'TypeS', power: '22,1 kW', amount: 3 },
  ],
  imcompatibles: [
    { title: 'Lente', subtitle: 'Prise murale', power: '3,68 kW', amount: 3 },
  ],
  timeCost: '14 min',
  distance: '7.6 km',
}
export const PlaceDetails = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectRef = useRefContext()
  const handleBack = () => navigate(-1)
  const handleClose = () => navigate('/location', { state: pathname })
  const { place, address, compatibles, imcompatibles, timeCost, distance } =
    ExampleData

  const handleAddPlace = () =>
    navigate('/location/add_place', { state: pathname })
  return (
    <div className="m-2 divide-y-2 overflow-y-auto">
      <div className="flex justify-between">
        <button className="m-2" onClick={handleBack}>
          <NavIcon name="back" />
        </button>
        <button
          className="m-2"
          onClick={handleClose}
          ref={selectRef('placeDetailsClose')}
        >
          <NavIcon name="close" />
        </button>
      </div>
      <div>
        <h2>{place}</h2>
        <span className="p-2">{address}</span>
      </div>
      {compatibles && (
        <div>
          <h3>Compatibles</h3>
          {compatibles.map((item) => (
            <div key={item.title} className="flex">
              <div className="px-2">
                <NavIcon name="temp" />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex justify-between">
                  <div>{item.title}</div>
                  <div>{item.amount}</div>
                </div>
                <div>
                  <span>{item.subtitle}</span> - <span>{item.power}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {imcompatibles && (
        <div>
          <h3>Imcompatibles</h3>
          {imcompatibles.map((item) => (
            <div key={item.title} className="flex">
              <div className="px-2">
                <NavIcon name="temp" />
              </div>
              <div className="flex flex-col w-full">
                <div className="flex justify-between">
                  <div>{item.title}</div>
                  <div>{item.amount}</div>
                </div>
                <div>
                  <span>{item.subtitle}</span> - <span>{item.power}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex">
        <div className="flex px-2 justify-center items-center text-orange-700">
          <NavIcon name="battery" />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            <div>Recharge nécessaire</div>
          </div>
          <div>
            <span>0 % à l'arrivée</span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-6 gap-4 text-center">
        <button
          className="col-span-5 blue-circle-button w-full"
          ref={selectRef('addLocation')!}
          onClick={handleAddPlace}
        >
          <NavIcon name="add_location" />
          <span>Ajouter</span>
        </button>
        <button className="slate-circle-button w-full">
          <NavIcon name="routes" />
        </button>
      </div>
      <div className="text-center">
        <span className="text-teal-700">{timeCost}</span> -{' '}
        <span>{distance}</span>
      </div>
    </div>
  )
}
