import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { refContext } from '../../App'
import { useRefContext } from './useRefContext'

const Delay = 1000
export const useRefFocus = (delay: number = Delay) => {
  const { state, pathname } = useLocation()
  const refObjects = useContext(refContext)
  useEffect(() => {
    // console.log('[useRefFocus] navigate state', state, 'pathname', pathname)
    const timer = setTimeout(() => {
      // senario 1
      if (state === '/location') refObjects.mapSearch.current?.focus()
      if (document.activeElement === refObjects.mapSearch.current) refObjects.mapPlace.current?.focus()
      if (state === '/location/add_place')
        refObjects.launcherIcon.current?.focus()
      if (state === '/location/details') refObjects.audioIcon.current?.focus()
      if (state === '/music/radio/favorites')
        refObjects.phoneIcon.current?.focus()
      if (pathname === '/phone') refObjects.settingsIcon.current?.focus()
      if (!state) refObjects.mapSearch.current?.focus()
      if (state === '/location/route') refObjects.selectRoutes.current?.focus()
      if (state === '/location/route_select')
        refObjects.navSettingsClick.current?.focus()
      if (state === '/location/settings')
        refObjects.nearbySearch.current?.focus()
      if (state === '/location/navigate') refObjects.placeClose.current?.focus()
      if (state === '/location/navigate')
        refObjects.navSettingsClick.current?.focus()
      if (pathname === '/location/settings') refObjects.navSettingsClose.current?.focus()
      if (state === '/location/navigate')
        refObjects.gasStationSearch.current?.focus()
      if (state === '/location/search') refObjects.nearbyResult.current?.focus()
      if (state === '/location/nearby/gas_station')
        refObjects.addLocation.current?.focus()
      if (state === '/location') refObjects.placeDetailsClose.current?.focus()
      if (state === '/location/details')
        refObjects.addLocationConfirm.current?.focus()
      if (state === '/location/info') refObjects.spotifyIcon.current?.focus()
      if (state === 'Spotify') refObjects.googlePlayIcon.current?.focus()
      if (state === 'Google Play Store')
        refObjects.locationIcon.current?.focus()
      if (state === '/launcher') refObjects.appPageBackIcon.current?.focus()
      if (state === '/launcher' && pathname === '/location')
        refObjects.assitantWidget.current?.focus()
      if (state === '/location') refObjects.radioIcon.current?.focus()
      if (state === '/music') refObjects.audioFavorite.current?.focus()
      if (
        state === '/music/radio/list' &&
        pathname === '/music/radio/favorites'
      )
        refObjects.audioList.current?.focus()
      if (state === '/phone' && pathname === '/drive') {
        const ref = refObjects.energyPage.current
        if (ref) ref.className = `${ref.className} ring-4`
      }
      if (state === '/drive') refObjects.chargeButton.current?.focus()
      if (state === '/drive/electric/charge') {
        const ref = refObjects.driveSettings.current
        if (ref) ref.className = `${ref.className} ring-4`
      }
      if (state === '/drive' && pathname === '/drive/preferences')
        refObjects.settingsSystem.current?.focus()
      if (
        state === '/drive/preferences' &&
        pathname === '/drive/preferences/system'
      )
        refObjects.settingsLanguage.current?.focus()
      if (state === '/drive/preferences' && pathname === '/drive') {
        const ref = refObjects.driveEcoMode.current
        if (ref) ref.className = `${ref.className} ring-4`
      }
      if (state === '/drive' && pathname === '/drive/configure/SPORT/conduite')
        refObjects.driveLife.current?.focus()
      if (state === '/drive/configure/SPORT/conduite')
        refObjects.driveConduct.current?.focus()

      // Senario 7
      if (pathname === '/profiles/users') refObjects.userName.current?.focus()
      if (pathname === '/profiles/modify/Driver')
        refObjects.changeNameInput.current?.focus()
      if (state === '/profiles/modify/Driver')
        refObjects.profileProtection.current?.focus()
      if (state === '/profiles/users' && pathname === '/profiles/Protection%20du%20profil')
        refObjects.chooseLock.current?.focus()
      if (pathname === '/profiles/Protection%20du%20profil/Choisir%20verrouillage')
        refObjects.subPrefClose.current?.focus()
    }, delay)
    return () => {
      clearTimeout(timer)
    }
  }, [state, document.activeElement])
}
