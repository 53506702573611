import { useReducer } from 'react'

type Reducer<S, A> = (prevState: S, action: A) => S
type State = {
  one: string
  two: string
}

type Action = {
  type: string
  payload: string
}
const initialState: State = {
  one: 'audio',
  two: 'assistant_google',
}
const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'one':
      state.one = action.payload
      break
    case 'two':
      state.two = action.payload
      break
    default:
      break
  }
  return {...state}
}
export const useWidget = () => {
  return useReducer(reducer, initialState)
}
