import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { WidgetMenu } from '../../conf/properties'
import SamplePic from '../../assets/home-swiper-nav-on.jpg'
import { switchContext } from '../../App'
import { useWidget } from '../hooks/useWidget'
import { widgetContext } from '../router'
import classNames from 'classnames'

export const SwitchWidgets = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className="w-full h-full">
      <div
        className="grid grid-cols-4 gap-4 place-content-around w-full pt-4 mt-4 z-10 bg-indigo-500 "
        id="widget"
        ref={ref}
      >
        {WidgetMenu.map((menu) => (
          <PerWidget key={menu} item={menu} />
        ))}
      </div>
    </div>
  )
})
const PerWidget: React.FC<{ item: typeof WidgetMenu[number] }> = ({ item }) => {
  const { t } = useTranslation()
  const [get, set] = useContext(switchContext)
  const [state, dispatch] = useContext(widgetContext)
  const isActive = state[get[0]] === item
  const activeClass = 'text-zinc-400'
  
  const handleLauncherClick = (item: typeof WidgetMenu[number]) => {
    dispatch({ type: get[0], payload: item })
    set([get[0], false])
  }
  return (
    <div
      // className="flex flex-col items-center"
      className={classNames(`flex flex-col items-center hover:${activeClass}`, isActive && activeClass)}
      onClick={() => handleLauncherClick(item)}
    >
      <img src={SamplePic} alt="samplePic" className="w-24 h-24" />
      <div className="text-center">
        <span>{t(item)}</span>
      </div>
    </div>
  )
}
