import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRefContext } from '../hooks/useRefContext'
import { NavIcon } from './NavIcon'

const exampleData = [
  { index: 'A', time: '14 min', distance: '7,7 km' },
  { index: 'B', time: '6 min plus long', distance: '9,7 km' },
]
export const PlaceResult = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectRef = useRefContext()
  const handleClose = () => navigate('/location/navigate', { state: pathname })
  return (
    <div className="m-2 divide-y-2">
      <div className="flex justify-between">
        <span>Autres itinéraires</span>
        <button onClick={handleClose} ref={selectRef('placeClose')!}>
          <NavIcon name="close" />
        </button>
      </div>
      <div className="flex items-center">
        <div className="m-2">
          <NavIcon name="options" />
        </div>
        <div className="p-2">Options d'itinéraire</div>
      </div>
      {exampleData.map((item, index) => (
        <PlaceInfo key={index} {...item} />
      ))}
    </div>
  )
}

interface PlaceInfoProps {
  index: string
  time: string
  distance: string
}
const PlaceInfo: React.FC<PlaceInfoProps> = ({ index, time, distance }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex">
        <div className="flex m-2 justify-center">
          <button className="slate-circle-button w-fit h-fit">{index}</button>
        </div>
        <div className="flex flex-col m-2">
          <div>{time}</div>
          <div>{distance}</div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex text-orange-700">
          <span>0 %</span>
          <NavIcon name="battery" />
        </div>
      </div>
    </div>
  )
}
